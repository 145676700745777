import React from 'react'
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'


import '../Pages/assets/css/form.css'

function DyInputSchool({getter, setter}) {

    const addFields = () => {
        let newfield = ""
        handleInputChange([...getter, newfield])
    }

    const removeFields = (index) => {
        let data = [...getter];
        data.splice(index, 1)
        handleInputChange(data);
    }
    const handleInputChange = useCallback(event => {
        setter(event)
    }, [setter])
    const handleFormChange = (index, event) => {


        let data = [...getter];

        data[index] = event.target.value.slice(0,30);

        handleInputChange(data);



    }


    return (
        <>

            <div class="form-group">
                <label for="inputsm" className='inputsm'>Agregar Formaciòn académica<span></span></label>
                {
                    getter.map((frase, index) => (
                        <div className="dy-form">
                            <input key={index} class="form-control input-sm smal" type="text" value={frase} onChange={event => handleFormChange(index, event)} />
                            <div className="deleteicon">
                                
                                <FontAwesomeIcon key={index} icon={faTrash} onClick={e => removeFields(index)} />
                            </div>
                        </div>
                    ))
                }
                <div className="addFases">
                    <FontAwesomeIcon icon={faCirclePlus} onClick={addFields} />
                </div>
            </div>


        </>
    )
}

export default DyInputSchool