import React from 'react'
import './Card.css'


function Card({ name, data, icon }) {
  return (<>
    <div className='cardDash' style={{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    }}>
      <div>
        <div className='cardDashTitle'>{name}</div>
        <div className='cardDashValue'>{data}</div>
      </div>
      <div  className='cardDashIcon'>
        {icon}
      </div>

    </div>
  </>
  )
}

export default Card