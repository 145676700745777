import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'

export default function JobsList({ data }) {
    return (



        <div style={{
            height: "140px",
            overflow: "scroll",
            overflowX: "hidden",
        }}>
            {
                data.map((job) => {
                    return (
                        <div style={{
                            //border bottom
                            borderBottom: '1px solid #e0e0e0',
                            display: 'flex',
                        }}>

                            <FontAwesomeIcon className='iconchek' icon={faSuitcase} />
                            <div className='cardjobtitle' style={{
                                fontWeight: '300',
                                fontSize: '14px',
                                marginLeft: '10px',
                            }}>
                                {job.nombreTrabajo} --- {job.nombreEmpresa}
                            </div>
                        </div>
                    )
                })
            }

        </div>

    )
}
