import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from 'react'

///All Inputs Dynamic
import DynamicInput from './DynamicInput';
import DynamicInputJobs from './DynamicInputJobs';
import DyInputCourses from './DyInputCourses';
import DyInputLangua from './DyInputLangua';
import DyInputSchool from './DyInputSchool';
import '../Pages/assets/css/form.css'
import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 991 })
    return isMobile ? children : null
}




function Inputs({ idx, setidx, postul, setpostul, fA, setfA, habilidadesB, sethabilidadesB,

    JobE, setJobsE, JobP, setJobsP, JobFI, setJobsFI, JobFF, setJobsFF, fCurso, setfCurso, nCurso, setnCurso, lang, setlang, langl, setlangpl


}) {


    const handleFormInfoChange = (event) => {

        if (event.target.name == "resume") {
            setpostul({
                ...postul,
                [event.target.name]: event.target.value.slice(0, 1510)
            })
        } else {
            setpostul({
                ...postul,
                [event.target.name]: event.target.value.slice(0, 40)
            })
        }
    }


    const handleBack = e => {

        if (idx != 1) {
            setidx(idx - 1)
        }

    }


    ///los selects
    const options = [
        { value: "casado", label: "casado" },
        { value: "soltero", label: "soltero" },
        { value: "otro", label: "otro" }
    ]



    if (idx == 1) {
        return (
            <>

                <Desktop >


                    <div className='formTitle'>

                        <span className="formTitle">Datos personales</span>
                    </div>


                    <div data-aos="fade-left">

                        <div key={idx} class="form-group">
                            <label for="inputsm" className='inputsm'>Nombre(s)<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.nombre} name="nombre" id="inputsm" onChange={handleFormInfoChange} type="text" />
                        </div>

                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Apellidos<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.apellidos} name="apellidos" id="inputsm" onChange={handleFormInfoChange} type="text" />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>CURP<span>(obligatorio)</span></label>
                            <input class="form-control  input-sm smal" value={postul.curp} name="curp" maxLength={18} id="inputsm" onChange={handleFormInfoChange} type="text" />
                        </div>

                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Telefono<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.tel} name="tel" id="inputsm" onChange={handleFormInfoChange} type="number" placeholder='Numero sin espacios ejemplo : 4498765631' />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Domicilio<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.domicilio} name="domicilio" id="inputsm" onChange={handleFormInfoChange} type="text" />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>E-mail<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.email} name="email" id="inputsm" onChange={handleFormInfoChange} type="email" />
                        </div>


                    </div>
                </Desktop>

                <Mobile>

                    <div className='formTitle'>

                        <span className="formTitle">Datos personales</span>
                    </div>


                    <div>

                        <div key={idx} class="form-group">
                            <label for="inputsm" className='inputsm'>Nombre(s)<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.nombre} name="nombre" id="inputsm" onChange={handleFormInfoChange} type="text" />
                        </div>

                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Apellidos<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.apellidos} name="apellidos" id="inputsm" onChange={handleFormInfoChange} type="text" />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>CURP<span>(obligatorio)</span></label>
                            <input class="form-control  input-sm smal" value={postul.curp} name="curp" maxLength={18} id="inputsm" onChange={handleFormInfoChange} type="text" />
                        </div>

                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Telefono<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.tel} name="tel" id="inputsm" onChange={handleFormInfoChange} type="number" placeholder='Numero sin espacios ejemplo : 4498765631' />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Domicilio<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.domicilio} name="domicilio" id="inputsm" onChange={handleFormInfoChange} type="text" />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>E-mail<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" value={postul.email} name="email" id="inputsm" onChange={handleFormInfoChange} type="email" />
                        </div>


                    </div>



                </Mobile>
            </>
        );
    }

    else if (idx == 2) {
        return (
            <>

                <Desktop>



                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Datos personales</span>
                    </div>

                    <div key={idx} data-aos="fade-left">
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Calle y Numero<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="text" value={postul.calle_Num} name="calle_Num" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Colonia<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="text" value={postul.col} name="col" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>C.P.<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="number" value={postul.cp} name="cp" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Lugar de Nacimiento<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="Text" value={postul.lugarN} name="lugarN" onChange={handleFormInfoChange} />
                        </div>


                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Fecha de Nacimiento<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal2" id="inputsm" type="date" value={postul.fechaN} name="fechaN" onChange={handleFormInfoChange} />
                        </div>
                    </div>

                </Desktop>

                <Mobile>

                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Datos personales</span>
                    </div>

                    <div key={idx} >
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Calle y Numero<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="text" value={postul.calle_Num} name="calle_Num" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Colonia<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="text" value={postul.col} name="col" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>C.P.<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="number" value={postul.cp} name="cp" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Lugar de Nacimiento<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="Text" value={postul.lugarN} name="lugarN" onChange={handleFormInfoChange} />
                        </div>


                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Fecha de Nacimiento<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal2" id="inputsm" type="date" value={postul.fechaN} name="fechaN" onChange={handleFormInfoChange} />
                        </div>
                    </div>





                </Mobile>
            </>
        )

    }

    else if (idx == 3) {
        return (

            <>

                <Desktop>

                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Datos personales</span>
                    </div>



                    <div key={idx} data-aos="fade-left">
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Edad<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="number" value={postul.edad} name="edad" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Sexo<span>(opcional)</span></label>
                            <select className='input-sm smal2 form-select' name="sexo" value={postul.sexo} onChange={handleFormInfoChange}>
                                <option value="otro">otro..</option>
                                <option value="hombre">Hombre</option>
                                <option value="mujer">Mujer</option>
                            </select>


                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Linked-In<span>(URL Opcional)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" value={postul.linked} name="linked" type="text" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Nacionalidad<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="text" value={postul.nacionalidad} name="nacionalidad" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Estado civil<span>(obligatorio)</span></label>

                            <select className='input-sm smal2 form-select' name="Ecivil" value={postul.Ecivil} onChange={handleFormInfoChange}>
                                <option value="otro">otro..</option>
                                <option value="casado">casado</option>
                                <option value="Soltero">soltero</option>
                            </select>


                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>¿Cuenta con licencia?<span>(obligatorio)</span></label>
                            <select class="input-sm smal2 form-select" name="licencia" value={postul.licencia} onChange={handleFormInfoChange}>
                                <option class="smal2" value=""></option>
                                <option class="smal2" value="si">si</option>
                                <option class="smal2" value="no">no</option>
                            </select>
                        </div>
                    </div>

                </Desktop>

                <Mobile>
                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Datos personales</span>
                    </div>



                    <div key={idx} >
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Edad<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="number" value={postul.edad} name="edad" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Sexo<span>(opcional)</span></label>
                            <select className='input-sm smal2 form-select' name="sexo" value={postul.sexo} onChange={handleFormInfoChange}>
                                <option value="otro">otro..</option>
                                <option value="hombre">Hombre</option>
                                <option value="mujer">Mujer</option>
                            </select>


                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Linked-In<span>(URL Opcional)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" value={postul.linked} name="linked" type="text" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Nacionalidad<span>(obligatorio)</span></label>
                            <input class="form-control input-sm smal" id="inputsm" type="text" value={postul.nacionalidad} name="nacionalidad" onChange={handleFormInfoChange} />
                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>Estado civil<span>(obligatorio)</span></label>

                            <select className='input-sm smal2 form-select' name="Ecivil" value={postul.Ecivil} onChange={handleFormInfoChange}>
                                <option value="otro">otro..</option>
                                <option value="casado">casado</option>
                                <option value="Soltero">soltero</option>
                            </select>


                        </div>
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'>¿Cuenta con licencia?<span>(obligatorio)</span></label>
                            <select class="input-sm smal2 form-select" name="licencia" value={postul.licencia} onChange={handleFormInfoChange}>
                                <option class="smal2" value=""></option>
                                <option class="smal2" value="si">si</option>
                                <option class="smal2" value="no">no</option>
                            </select>
                        </div>
                    </div>


                </Mobile>
            </>
        )

    }


    else if (idx == 4) {
        return (
            <>
                <Desktop>


                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Formación académica</span>
                    </div>
                    <div key={idx} data-aos="fade-left">
                        <DyInputSchool getter={fA} setter={setfA} postul={postul} setpostul={setpostul} />
                    </div>

                </Desktop>


                <Mobile>


                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Formación académica</span>
                    </div>
                    <div key={idx} >
                        <DyInputSchool getter={fA} setter={setfA} postul={postul} setpostul={setpostul} />
                    </div>
                </Mobile>

            </>

        )
    }


    else if (idx == 5) {
        return (
            <>

                <Desktop>


                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Habilidades blandas</span>
                    </div>
                    <div key={idx} data-aos="fade-left">
                        <DynamicInput getter={habilidadesB} setter={sethabilidadesB} />
                    </div>

                </Desktop>

                <Mobile>

                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Habilidades blandas</span>
                    </div>
                    <div key={idx} >
                        <DynamicInput getter={habilidadesB} setter={sethabilidadesB} />
                    </div>


                </Mobile>
            </>

        )
    }

    else if (idx == 6) {
        return (
            <>
                <Desktop>
                    <div className='formTitle'>

                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>

                        <span className="formTitle">Experiencia Laboral</span>
                    </div>

                    <div key={idx} data-aos="fade-left">
                        <DynamicInputJobs
                            getter={JobE}
                            setter={setJobsE}
                            JobP={JobP}
                            setJobsP={setJobsP}
                            JobFI={JobFI}
                            setJobsFI={setJobsFI}
                            JobFF={JobFF}
                            setJobsFF={setJobsFF}

                        />
                    </div>


                </Desktop>
                <Mobile>

                    <div className='formTitle'>

                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>

                        <span className="formTitle">Experiencia Laboral</span>
                    </div>

                    <div key={idx} >
                        <DynamicInputJobs
                            getter={JobE}
                            setter={setJobsE}
                            JobP={JobP}
                            setJobsP={setJobsP}
                            JobFI={JobFI}
                            setJobsFI={setJobsFI}
                            JobFF={JobFF}
                            setJobsFF={setJobsFF}

                        />
                    </div>


                </Mobile>



            </>

        )
    }

    else if (idx == 7) {
        return (
            <>
                <Desktop>
                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Capacitación o cursos</span>
                    </div>
                    <div key={idx} data-aos="fade-left">
                        <DyInputCourses getter={nCurso} setter={setnCurso} fCurso={fCurso} setfCurso={setfCurso} />
                    </div>

                </Desktop>

                <Mobile>
                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Capacitación o cursos</span>
                    </div>
                    <div key={idx}>
                        <DyInputCourses getter={nCurso} setter={setnCurso} fCurso={fCurso} setfCurso={setfCurso} />
                    </div>

                </Mobile>
            </>

        )
    }
    else if (idx == 8) {
        return (
            <>
                <Desktop>


                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Idiomas y nivel</span>
                    </div>
                    <div key={idx} data-aos="fade-left">
                        <DyInputLangua getter={lang} setter={setlang} langl={langl} setlangpl={setlangpl} />
                    </div>

                </Desktop>
                <Mobile>
                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Idiomas y nivel</span>
                    </div>
                    <div key={idx} >
                        <DyInputLangua getter={lang} setter={setlang} langl={langl} setlangpl={setlangpl} />
                    </div>

                </Mobile>
            </>

        )
    }


    else if (idx == 9) {

        return (

            <>

                <Desktop>


                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Resumen Profesional</span>
                    </div>


                    <div key={idx} data-aos="fade-left">
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'><span>(breve descripciòn de su desarrollo profesional o características del aspirante que <br /> requieran ser destacadas) </span></label>
                            <textarea class="form-control input-sm bigger" id="inputsm" name="resume" type="text" value={postul.resume} onChange={handleFormInfoChange} />
                        </div>
                    </div>
                </Desktop>

                <Mobile>
                    <div className='formTitle'>
                        <div className='back' onClick={e => { handleBack() }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <span className="formTitle">Resumen Profesional</span>
                    </div>


                    <div key={idx} >
                        <div class="form-group">
                            <label for="inputsm" className='inputsm'><span>(breve descripciòn de su desarrollo profesional o características del aspirante que <br /> requieran ser destacadas) </span></label>
                            <textarea class="form-control input-sm bigger" id="inputsm" name="resume" type="text" value={postul.resume} onChange={handleFormInfoChange} />
                        </div>
                    </div>



                </Mobile>

            </>

        )
    }



}


export default Inputs