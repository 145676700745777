import React from 'react'
import { Link } from "react-router-dom";

import notdounf from '../Pages/assets/images/404.svg'

export default function JobVisualOne({job}) {


        if (job === null) {
            return (
                <div>
                    cargando
                </div>
            )
        }

        return (
            <>

                <div className='card3' style={{
                        padding: '0px',
                        margin: '0px',
                    }}>
                    <div className='card4' style={
                        {
                            marginBottom: '0px',
                            height: 'auto',
                        }
                    }>
                        <div>
                            <div className='Card2Title'>{job.nombreTrabajo}</div>
                        </div>

                        <div className='.Card2em'>{job.nombreEmpresa}</div>
                        <div className='.Card2em'>{job.ub}</div>
                        <div className='.Card2em'>
                            {job.salario[0]}.00-{job.salario[1]}.00/{job.salario[2]} - {job.tipo}

                        </div>
           
                    </div>

                    <div className='scrollable-div'>
                        <div className='cardinsider'>
                            <div>
                                <div className='Card2Title'>Detalles del empleo</div>
                            </div>
                            <div className='subs'>Salario</div>
                            {job.salario[0]}.00-{job.salario[1]}.00/{job.salario[2]} - {job.tipo}
                            <div className='subs'>Tipo de empleo</div>
                            {job.tipo}

                        </div>

                        <div className='cardinsider'>
                            <div>
                                <div className='Card2Title'>Beneficios</div>
                                {
                                    job.beneficios.map((benefit) =>
                                        <div className='benefits'>{benefit}</div>
                                    )
                                }
                            </div>
                        </div>

                        <div className='cardinsider'>
                            <div>
                                <div className='Card2Title'>Descripción completa del empleo</div>
                            </div>
                            {job.fullDes}
                        </div>
                    </div>
                </div >

            </>
        )
    
}
