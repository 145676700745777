
import React from 'react';
import { TailSpin } from 'react-loader-spinner'
import '../App.css';
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}



function Spiner({ loading }) {
  if (loading ) {
    return (<div>

      <Desktop>
        <div className='spiner'>
          <TailSpin
            height="80"
            width="80"
            color="#FB9C00"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </Desktop>

      <Mobile>

      <div className='spiner2'>
          <TailSpin
            height="80"
            width="80"
            color="#FB9C00"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>



      </Mobile>


    </div>)
  }
  return (<></>)
}

export default Spiner;