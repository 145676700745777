import React, { Fragment, useEffect, useRef, useState, } from 'react'
import { useNavigate, Link, useLocation } from "react-router-dom";
import './assets/css/form.css'
import ProgressBar from 'react-animated-progress-bar';
import Aos from 'aos'
import Inputs from '../Components/Inputs';
import 'aos/dist/aos.css'
import sider from './assets/images/sider.svg'

import Jobsimg from "../Pages/assets/images/jobs.svg"

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Uploader from '../Components/Uploader'

import { useMediaQuery } from 'react-responsive'
import taken from './assets/images/taken.svg'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'

import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}

function Form({ masterid, setmasterid }) {


    //Hooks

    const [habilidadesB, sethabilidadesB] = useState(["", "", ""])

    const [fA, setfA] = useState(["", "", ""])


    //Jobs
    const [JobE, setJobsE] = useState([""])
    const [JobP, setJobsP] = useState([""])
    const [JobFI, setJobsFI] = useState([""])
    const [JobFF, setJobsFF] = useState([""])

    //Cursos
    const [nCurso, setnCurso] = useState([""])
    const [fCurso, setfCurso] = useState([""])

    //Lang
    const [lang, setlang] = useState([""])
    const [langl, setlangpl] = useState([""])

    //resume
    //const [resume, setresume] = useState("")

    const [toggle, settoggle] = useState(false)

    const navigate = useNavigate();


    useEffect(() => {
        //console.log(masterid)
    }, [masterid])





    const [postul, setpostul] = useState({

        idJob: "",
        nombre: "",
        apellidos: "",
        curp: "",
        tel: "",
        domicilio: "",
        email: "",
        calle_Num: "",
        col: "",
        cp: "",
        lugarN: "",
        fechaN: "",
        edad: "",
        sexo: "otro",
        linked: "",
        nacionalidad: "",
        Ecivil: "otro",
        licencia: "",
        fA: [],
        habilidadesB: [],
        jobE: [],
        JobP: [],
        JobFI: [],
        JobFF: [],
        nCurso: [],
        fCurso: [],
        lang: [],
        resume: ""
    });


    //setpostul(postul => ({
    //...postul,
    //...updatedPostul
    //}))

    //Aoss
    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, [])


    //IDX
    const [idx, setidx] = useState(1)

    const notify = () => toast.error("Todos los campos son obligatorios.");
    const notifyres = () => toast.error("Resumen Profesional Obligatorio");
    const notifysql = () => toast.error("¡Sin caracteres especiales ni números, por favor!");
    const notifysql2 = () => toast.error("¡Sin caracteres especiales , por favor revisa el campo de domicilio!");
    const notifysql3 = () => toast.error("¡E-mail no valido!");
    const notifysql4 = () => toast.error("¡CURP no valido!");
    const notifysql5 = () => toast.error("¡Sin caracteres especiales , por favor revisa el campo de Calle y Numero!");
    const notifysql6 = () => toast.error("¡Sin caracteres especiales , por favor revisa el campo de  Colonia!");
    const notifysql7 = () => toast.error("¡Sin caracteres especiales , por favor revisa el campo de  Lugar de Nacimiento!");
    const notifysql8 = () => toast.error("¡url Linked-In no valido!");

    const notifysql9 = () => toast.error("¡Sin caracteres especiales");

    const handleC = (e) => {
        const onlyLettersPattern = /^[A-Za-z\u00C0-\u017F ]+$/;
        const lettersandnumbers = /^[A-Za-z0-9\u00C0-\u017F ]+$/;

        const validateEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const validateCurp = /^[A-Z][A-Z][A-Z][A-Z]\d{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[MH][A-Z]{2}[B-DF-HJ-NP-TV-Z]{3}[A-Z0-9][0-9]$/;
        const validateLinked = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        const validateStpaces = /^[ ]*$/
        const lsum = /^[a-zA-ZÀ-ÿ0-9\s]*$/u
        const ResumenVal = /^[a-zA-ZÀ-ÿ0-9\s.,]*$/u

        if (idx == 1 && (postul.nombre == "" || postul.apellidos == "" || postul.curp == "" || postul.tel == "" || postul.domicilio == "" || postul.email == "")) {
            notify();
        } else if (idx == 2 && (postul.calle_Num == "" || postul.col == "" || postul.cp == "" || postul.lugarN == "" || postul.fechaN == "")) {
            notify();
        } else if (idx == 3 && (postul.edad == "" || postul.nacionalidad == "" || postul.Ecivil == "" || postul.licencia == "")) {
            notify();
        } else if (idx == 9 && (postul.resume == "")) {
            notifyres();

        }
        else if (idx == 9 && !postul.resume.match(ResumenVal)) {
            notifysql9();
        }
        else if (idx == 9 && (postul.resume != "")) {
            settoggle(true)
        }

        ///input validation
        else if (idx == 1 && !postul.nombre.match(onlyLettersPattern) ||
            idx == 1 && !postul.apellidos.match(onlyLettersPattern)) {
            notifysql();
        }

        else if (idx == 1 && !postul.domicilio.match(lettersandnumbers)) {
            notifysql2();
        }

        else if (idx == 1 && !postul.email.match(validateEmail)) {
            notifysql3();
        }


        else if (idx == 1 && !postul.curp.match(validateCurp)) {
            notifysql4();
        }

        else if (idx == 2 && !postul.calle_Num.match(lettersandnumbers)) {
            notifysql5();
        }
        else if (idx == 2 && !postul.col.match(lettersandnumbers)) {
            notifysql6();
        }

        else if (idx == 2 && !postul.lugarN.match(lettersandnumbers)) {
            notifysql7();
        }

        else if (idx == 3 && !postul.linked.match(validateStpaces) && !postul.linked.match(validateLinked)) {
            notifysql8();
        }
        else if (idx == 4 && fA.some(element => !lsum.test(element))) {

            notifysql9();
        }
        else if (idx == 5 && habilidadesB.some(element => !lsum.test(element))) {

            notifysql9();
        }

        else if (idx == 6 && (JobE.some(element => !lsum.test(element)) || JobP.some(element => !lsum.test(element)))) {

            notifysql9();
        }

        else if (idx == 7 && nCurso.some(element => !lsum.test(element))) {

            notifysql9();
        }

        else if (idx == 8 && lang.some(element => !lsum.test(element))) {

            notifysql9();
        }





        else {
            if (idx < 9) {
                setidx(idx + 1)
            }
        }

    }

    if (masterid == null) {
        return (<div>
            <Desktop>
                <div style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}>

                    <img style={{
                        width: '20%',
                    }} src={taken} />
                    <div>
                        <p style={{
                            marginTop: '1rem',
                        }}>Necesitas seleccionar un trabajo para realizar el registro. </p>

                    </div>
                    <Link to='/bolsadetrabajo' style={{
                        cursor: "pointer",
                        fontSize: "1.2rem",
                        marginTop: "1rem",
                        textDecoration: "none",

                    }}>
                        <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Regresar
                    </Link>




                </div>

            </Desktop>

            <Mobile>

                                <div style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}>

                    <img style={{
                        width: '50%',
                    }} src={taken} />
                    <div>
                        <p style={{
                            marginTop: '1rem',
                            textAlign: 'center',
                            justifyContent: 'center',
                            padding: '2rem',
                        }}>Necesitas seleccionar un trabajo para realizar el registro. </p>

                    </div>
                    <Link to='/bolsadetrabajo' style={{
                        cursor: "pointer",
                        fontSize: "1.2rem",
                        marginTop: "1rem",
                        textDecoration: "none",

                    }}>
                        <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Regresar
                    </Link>




                </div>


            </Mobile>


        </div>)
    }

    return (
        <div>


            {/* <div className='container'>
                <div class="row">
                    <div class="col sider-contl"> */}


            {/* <Inputs idx={idx} habilidadesB={habilidadesB} sethabilidadesB={sethabilidadesB} Jobs={Jobs} setJobs={setJobs}/> */}

            {/* <div className='postulbot'> */}
            {/* <button className="btn btn-primary btn-submit" onClick={e => handleC()}>Continuar</button> */}
            {/* </div>
                    </div>
                    <div class="col sider-cont" data-aos="zoom-in">
                        <img className='sider' src={sider}></img>
                    </div>

                </div>

            </div>
             */}
            <Desktop>
                <>
                    <ToastContainer />


                    <div className='containerForm'>

                        <div className='art' data-aos="fade-right">

                            <div className="header ">
                                <div className="branding">
                                    <div className="container-fluid position-relative py-3">
                                        <div className="logo-wrapper">
                                            <div className="site-logo"><Link to="/" className="navbar-brand" href="index.html">
                                                <span className="logo-text2">Innovatia</span>
                                            </Link></div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='josblogoContainer'>

                                <img className="jobslogo" src={Jobsimg} alt="jobs" />

                            </div>

                        </div>

                        <div className='containerForml'>
                            <Inputs idx={idx} setidx={setidx}


                                postul={postul}
                                setpostul={setpostul}

                                habilidadesB={habilidadesB}
                                sethabilidadesB={sethabilidadesB}

                                fA={fA}
                                setfA={setfA}


                                JobE={JobE}
                                setJobsE={setJobsE}
                                JobP={JobP}
                                setJobsP={setJobsP}
                                JobFI={JobFI}
                                setJobsFI={setJobsFI}
                                JobFF={JobFF}
                                setJobsFF={setJobsFF}

                                nCurso={nCurso}
                                setnCurso={setnCurso}

                                fCurso={fCurso}
                                setfCurso={setfCurso}

                                lang={lang}
                                setlang={setlang}

                                langl={langl}
                                setlangpl={setlangpl}
                            />


                            <div className='postulbot'>
                                <button className="btn btn-primary btn-submit" onClick={e => handleC()}>{
                                    idx < 9 ? "Continuar" : "Enviar"
                                }</button>
                            </div>
                        </div>
                    </div>

                    <Uploader
                        postul={postul}
                        masterid={masterid}
                        toggle={toggle}
                        fA={fA}
                        habilidadesB={habilidadesB}
                        JobE={JobE}
                        JobP={JobP}
                        JobFI={JobFI}
                        JobFF={JobFF}
                        nCurso={nCurso}
                        fCurso={fCurso}
                        lang={lang}
                        langl={langl}

                        settoggle={settoggle} />


                    <div className="footer">
                        <div className="footer-bottom text-center py-3">
                            <div className="copyright">
                                innovatia.mx
                            </div>
                        </div>
                    </div>
                </>
            </Desktop>

            <Mobile>
                <div
                    style={{
                        overflowX: "hidden",

                    }}
                >
                    <ToastContainer />
                    <div className="header">
                        <div className="branding">
                            <div className="container-fluid position-relative py-3">
                                <div className="logo-wrapper">
                                    <div className="site-logo"><Link to="/" className="navbar-brand" href="index.html">
                                        <span className="logo-text">Innovatia</span>
                                    </Link></div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='containerForml'>
                        <Inputs idx={idx} setidx={setidx}


                            postul={postul}
                            setpostul={setpostul}

                            habilidadesB={habilidadesB}
                            sethabilidadesB={sethabilidadesB}

                            fA={fA}
                            setfA={setfA}


                            JobE={JobE}
                            setJobsE={setJobsE}
                            JobP={JobP}
                            setJobsP={setJobsP}
                            JobFI={JobFI}
                            setJobsFI={setJobsFI}
                            JobFF={JobFF}
                            setJobsFF={setJobsFF}

                            nCurso={nCurso}
                            setnCurso={setnCurso}

                            fCurso={fCurso}
                            setfCurso={setfCurso}

                            lang={lang}
                            setlang={setlang}

                            langl={langl}
                            setlangpl={setlangpl}
                        />


                        <div className='postulbot'>
                            <button className="btn btn-primary btn-submit" onClick={e => handleC()}>{
                                idx < 9 ? "Continuar" : "Enviar"
                            }</button>
                        </div>
                    </div>

                    <Uploader
                        postul={postul}
                        masterid={masterid}
                        toggle={toggle}
                        fA={fA}
                        habilidadesB={habilidadesB}
                        JobE={JobE}
                        JobP={JobP}
                        JobFI={JobFI}
                        JobFF={JobFF}
                        nCurso={nCurso}
                        fCurso={fCurso}
                        lang={lang}
                        langl={langl}

                        settoggle={settoggle} />


                    <div className="footer">
                        <div className="footer-bottom text-center py-3">
                            <div className="copyright">
                                innovatia.mx
                            </div>
                        </div>
                    </div>
                </div>
            </Mobile>


        </div>
    )
}

export default Form