import React from 'react'


import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}

function DynamicInputJobs({ getter, setter, JobP, setJobsP, JobFI, setJobsFI, JobFF, setJobsFF }) {


    //End date
    const addFields4 = () => {
        let newfield = ""
        handleInputChange4([...JobFF, newfield])
    }

    const removeFields4 = (index) => {
        let data = [...JobFF];
        data.splice(index, 1)
        handleInputChange4(data);
    }

    const handleInputChange4 = useCallback(event => {
        setJobsFF(event)
    }, [setJobsFF])

    const handleFormChange4 = (index, event) => {
        let data = [...JobFF];
        data[index] = event.target.value.slice(0, 20);
        handleInputChange4(data);
    }


    //start date

    const addFields3 = () => {
        let newfield = ""
        handleInputChange3([...JobFI, newfield])
    }

    const removeFields3 = (index) => {
        let data = [...JobFI];
        data.splice(index, 1)
        handleInputChange3(data);
    }

    const handleInputChange3 = useCallback(event => {
        setJobsFI(event)
    }, [setJobsFI])

    const handleFormChange3 = (index, event) => {
        let data = [...JobFI];
        data[index] = event.target.value.slice(0, 20);
        handleInputChange3(data);
    }








    //Name of the Job

    const addFields2 = () => {
        let newfield = ""
        handleInputChange2([...JobP, newfield])
    }

    const removeFields2 = (index) => {
        let data = [...JobP];
        data.splice(index, 1)
        handleInputChange2(data);
    }

    const handleInputChange2 = useCallback(event => {
        setJobsP(event)
    }, [setJobsP])

    const handleFormChange2 = (index, event) => {
        let data = [...JobP];
        data[index] = event.target.value;
        handleInputChange2(data);
    }




    ///Name of the Company
    const addFields = () => {
        let newfield = ""
        handleInputChange([...getter, newfield])
        addFields2()
        addFields3()
        addFields4()
    }

    const removeFields = (index) => {
        let data = [...getter];
        data.splice(index, 1)
        handleInputChange(data);
        removeFields2(index)
        removeFields3(index)
        removeFields4(index)
    }

    const handleInputChange = useCallback(event => {
        setter(event)
    }, [setter])

    const handleFormChange = (index, event) => {
        let data = [...getter];
        data[index] = event.target.value;
        handleInputChange(data);
    }


    return (
        <div>

            <Desktop>

                <div class="form-group">
                    <label for="inputsm" className='inputsm'>Agregar Experiencia<span></span></label>
                    {
                        getter.map((frase, index) => (
                            <div className="dy-form-j">
                                <div>
                                    <label for="inputsm" className='inputsm'><span>Nombre de la Empresa</span></label>
                                    <input key={index} class="form-control input-sm smal2" type="text" value={getter[index]} onChange={event => handleFormChange(index, event)} />

                                    <label for="inputsm" className='inputsm'><span>Nombre del Puesto</span></label>
                                    <input key={index} class="form-control input-sm smal2" type="text" value={JobP[index]} onChange={event => handleFormChange2(index, event)} />

                                    <div className='inner-form'>
                                        <div
                                            style={{
                                                marginRight: '10px'
                                            }}
                                        ><label for="inputsm" className='inputsm'><span>Fecha Inicio</span></label>
                                            <input key={index} class="form-control input-sm smal2" type="date" value={JobFI[index]} onChange={event => handleFormChange3(index, event)} /></div>

                                        <div>
                                            <label for="inputsm" className='inputsm'><span>Fecha Fin</span></label>
                                            <input key={index} class="form-control input-sm smal2" type="date" value={JobFF[index]} onChange={event => handleFormChange4(index, event)} />

                                        </div>
                                    </div>

                                </div>
                                <div className="deleteicon">
                                    <FontAwesomeIcon key={index} icon={faTrash} onClick={e => removeFields(index)} />
                                </div>
                            </div>
                        ))
                    }
                    <div className="addFases" style={{
                        width: '90%',
                    }}>
                        <FontAwesomeIcon icon={faCirclePlus} onClick={addFields} />
                    </div>
                </div>

            </Desktop>
            <Mobile>

                <div class="form-group">
                    <label for="inputsm" className='inputsm'>Agregar Experiencia<span></span></label>
                    {
                        getter.map((frase, index) => (
                            <div className="dy-form-j">
                                <div style={{
                                    width: '90%'
                                }}>
                                    <label for="inputsm" className='inputsm'><span>Nombre de la Empresa</span></label>
                                    <input key={index} class="form-control input-sm smal2" type="text" value={getter[index]} onChange={event => handleFormChange(index, event)} />

                                    <label for="inputsm" className='inputsm'><span>Nombre del Puesto</span></label>
                                    <input key={index} class="form-control input-sm smal2" type="text" value={JobP[index]} onChange={event => handleFormChange2(index, event)} />

                                 
                                        <div>
                                            
                                            <label for="inputsm" className='inputsm'><span>Fecha Inicio</span></label>
                                            <input key={index} class="form-control input-sm smal2" type="date" value={JobFI[index]} onChange={event => handleFormChange3(index, event)} /></div>

                                        <div>
                                        
                                            <label for="inputsm" className='inputsm'><span>Fecha Fin</span></label>
                                            <input key={index} class="form-control input-sm smal2" type="date" value={JobFF[index]} onChange={event => handleFormChange4(index, event)} />

                                        </div>
                                  

                                </div>
                                <div className="deleteicon">
                                    <FontAwesomeIcon key={index} icon={faTrash} onClick={e => removeFields(index)} />
                                </div>
                            </div>
                        ))
                    }
                    <div className="addFases">
                        <FontAwesomeIcon icon={faCirclePlus} onClick={addFields} />
                    </div>
                </div>
            </Mobile>


        </div>
    )
}

export default DynamicInputJobs