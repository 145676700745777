import React, { useEffect, useRef } from 'react'
import logo from './assets/images/site-logo.jpg'

import './assets/css/theme.css'
import './assets/css/theme2.css'
import lap from './assets/images/lap.png'
import benja from './assets/images/circulbenja.png'
import jose from './assets/images/jose2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { faMicrochip } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faWifi } from '@fortawesome/free-solid-svg-icons'
import { faIndustry } from '@fortawesome/free-solid-svg-icons'
import { faBrain } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from "react-router-dom";
import Carousel from 'react-elastic-carousel';


function Home() {
	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, [])

	const breakPoints = [
		{ with: 1, itemsToShow: 1 },
		{ with: 550, itemsToShow: 2 },
		{ with: 768, itemsToShow: 3 },
	]
	const settings = {
		infinite: true,

		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};


	const carouselRef = useRef(null);
	const totalPages = Math.ceil(3 / 1)
	let resetTimeout;
	return (
		<div style={{
			overflowX: 'hidden',
		}}>
			<div className="header">
				<div className="branding">
					<div className="container-fluid position-relative py-3">
						<div className="logo-wrapper">
							<div className="site-logo"><a className="navbar-brand" ><img className="logoIno" src={logo} alt="logo" />
							</a></div>
						</div>

					</div>
				</div>
			</div>

			<section className="hero-section">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-7 pt-5 mb-5 align-self-center">
							<div className="promo pe-md-3 pe-lg-5">
								<h1 className="headline mb-3 titleI">
									CLÚSTER DE TECNOLOGÍAS DE LA INFORMACIÓN DEL ESTADO DE  AGUASCALIENTES
								</h1>
								<div data-aos="fade-left" className="section-intro single-col-max mx-auto  mb-10">Somos el clúster de TI, conformado por empresarios, industria, centros de investigación e instituciones de educación, cuya responsabilidad es la coordinación y promoción de la industria del Software y las tecnologías de la información para el estado de Aguascalientes. </div>
								<div className="subheadline mb-4">


								</div>
								<div data-aos="fade-rigth" className="cta-holder row gx-md-3 gy-3 gy-md-0">
									<div className="col-12 col-md-auto">
										<Link className="btn btn-primary w-100" to="/bolsadetrabajo" >Bolsa de trabajo</Link>
									</div>
									<div className="col-12 col-md-auto">
										<a className="btn btn-secondary scrollto w-100" href="#benefits-section">Notas</a>
									</div>
								</div>

								<div className="hero-quotes mt-5">

								</div>
							</div>
						</div>
						<div className="col-12 col-md-5 mb-5 align-self-center">
							<div className="book-cover-holder">
								<img stye={{
									maxWidth: '2rem',
								}} className="img-fluid book-cover" src={logo} alt="book cover" />
								{/* <div className="book-badge d-inline-block shadow">
						    New<br/>Release
					    </div> */}
							</div>
						</div>
					</div>
				</div>
			</section>


			<section id="content-section" className="content-section theme-bg-light-gradient py-5">
				<div className="container">
					<div className="single-col-max mx-auto">
						<h2 className="section-heading text-center mb-5">PROCESO PARA

							CONVERTIRTE EN SOCIO

							2022-2024</h2>

					</div>

					<div className="lap">
						<div className="col-12 col-md-5">
							<div className="figure-holder mb-8">
								<img className="img-fluid lap" src={lap} alt="image" />
							</div>
						</div>
						<div className="col-12 col-md-5 mb-5">
							<div data-aos="fade-right" className="key-points mb-4 text-center">
								<ul className="key-points-list list-unstyled mb-4 mx-auto d-inline-block text-start">
									<li >  <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Ser una empresa o persona física del sector de TICS.</li>
									<li > <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Estar Inscrito en el Registro Federal de Contribuyentes.</li>
									<li > <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Llenar el registro del Clúster.</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Nombre Comercial.</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Nombre o Razón fiscal.</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />RFC.</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Dirección Fiscal.</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Giro / Especialidad Principal.</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Pagina Web.</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Contacto.</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Pago Único por registro de la empresa ($500) .</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Pago Único de Tarjeta por miembro ($100).</li>
									<li> <FontAwesomeIcon className='iconchek' icon={faCheckCircle} />Datos de cada miembro registrado.</li>

								</ul>
								<div className="text-center"><a className="btn btn-primary" href="https://airtable.com/shrZpY0eM0ZxvVQ6b">Registrate</a></div>
							</div>

						</div>
					</div>
				</div>
			</section>


			<section id="audience-section" className="audience-section py-5">
				<div className="container">
					<h2 className="section-heading text-center mb-4">BENEFICIOS PARA ASOCIADOS <br />

						2022-2024</h2>

					<div data-aos="fade-right" className="audience mx-auto">
						<div className="item row gx-3">
							<div className="col-auto item-icon"><FontAwesomeIcon className='iconchek' icon={faUserCheck} /></div>
							<div className="col">
								<h4 className="item-title">Acceso preferencial a eventos del Clúster</h4>

							</div>
						</div>
						<div className="item row gx-3">
							<div className="col-auto item-icon"><FontAwesomeIcon className='iconchek' icon={faUserCheck} /></div>
							<div className="col">
								<h4 className="item-title">Capacitaciones con líderes de la industria</h4>
							</div>
						</div>
						<div className="item row gx-3">
							<div className="col-auto item-icon"><FontAwesomeIcon className='iconchek' icon={faUserCheck} /></div>
							<div className="col">
								<h4 className="item-title">Inscripción anticipada a eventos del Clúster</h4>
							</div>
						</div>
						<div className="item row gx-3">
							<div className="col-auto item-icon"><FontAwesomeIcon className='iconchek' icon={faUserCheck} /></div>
							<div className="col">
								<h4 className="item-title">Capacitaciones y Formación continua</h4>

							</div>
						</div>

						<div className="item row gx-3">
							<div className="col-auto item-icon"><FontAwesomeIcon className='iconchek' icon={faUserCheck} /></div>
							<div className="col">
								<h4 className="item-title">Descuentos en educacion en idioma ingles</h4>

							</div>
						</div>
						<div className="item row gx-3">
							<div className="col-auto item-icon"><FontAwesomeIcon className='iconchek' icon={faUserCheck} /></div>
							<div className="col">
								<h4 className="item-title">Descuentos con universidades locales y nacionales</h4>
							</div>
						</div>
						<div className="item row gx-3">
							<div className="col-auto item-icon"><FontAwesomeIcon className='iconchek' icon={faUserCheck} /></div>
							<div className="col">
								<h4 className="item-title">Promocion de Servicios en el mercado internacional</h4>
							</div>
						</div>
						<div className="item row gx-3">
							<div className="col-auto item-icon"><FontAwesomeIcon className='iconchek' icon={faUserCheck} /></div>
							<div className="col">
								<h4 className="item-title">Otros descuentos...</h4>
							</div>
						</div>

						<div className="text-center"><a className="btn btn-primary" href="">Descargar Beneficios</a></div>
					</div>
				</div>
			</section>


			<section id="benefits-section" className="benefits-section theme-bg-light-gradient ">
				<div className="container py-5">
					<h2 className="section-heading text-center mb-3">COMITÉS DE TRABAJO
						MESA DIRECTIVA 2022-2024</h2>
					<div className="section-intro single-col-max mx-auto text-center mb-5">Se formularon mesas de trabajo para atender las necesidades de la industria de las Tecnologias de la Información asi como para promover las ares de tecnologia de mayor relevancia para la industria y difundir los avances tecnologicos de las mismas. </div>
					
					
					
					<div data-aos="fade-up" className="row text-center">
						<div className="item col-12 col-md-6 col-lg-4">
							<div className="item-inner p-3 p-lg-4">
								<div className="item-header mb-3">
									<div className="item-icon"><FontAwesomeIcon icon={faMicrochip} /></div>
									<h3 className="item-heading">Nuevas Tecnologias</h3>
								</div>
								<div className="item-desc">
									Comision responsable de promover tendencias y nuevas tecnologías en la industria.
								</div>
							</div>
						</div>
						<div className="item col-12 col-md-6 col-lg-4">
							<div className="item-inner p-3 p-lg-4">
								<div className="item-header mb-3">
									<div className="item-icon"><FontAwesomeIcon icon={faLightbulb} /></div>
									<h3 className="item-heading">Innovación</h3>
								</div>
								<div className="item-desc">
									Comision responsable de promover La innovacion, mejores practicas  y tecnología en todas las industrias
								</div>
							</div>
						</div>
						<div className="item col-12 col-md-6 col-lg-4">
							<div className="item-inner p-3 p-lg-4">
								<div className="item-header mb-3">
									<div className="item-icon"><FontAwesomeIcon icon={faLock} /></div>
									<h3 className="item-heading">Ciberseguridad</h3>
								</div>
								<div className="item-desc">
									Comision responsable de promover la Seguridad cibernetica y practicas seguras a todas las industrias
								</div>
							</div>
						</div>
						<div className="item col-12 col-md-6 col-lg-4">
							<div className="item-inner p-3 p-lg-4">
								<div className="item-header mb-3">
									<div className="item-icon"><FontAwesomeIcon icon={faWifi} /></div>
									<h3 className="item-heading">Internet de las cosas</h3>
								</div>
								<div className="item-desc">
									Comision responsable de promover el uso de nuevas tecnologias relacionadas al internet de las cosas
								</div>
							</div>
						</div>
						<div className="item col-12 col-md-6 col-lg-4">
							<div className="item-inner p-3 p-lg-4">
								<div className="item-header mb-3">
									<div className="item-icon"><FontAwesomeIcon icon={faIndustry} /></div>
									<h3 className="item-heading">Industria 4.0</h3>
								</div>
								<div className="item-desc">
									Comision responsable de promover la implementacion y mejores practicas de la industria 4.o en la industria del estado
								</div>
							</div>
						</div>
						<div className="item col-12 col-md-6 col-lg-4">
							<div className="item-inner p-3 p-lg-4">
								<div className="item-header mb-3">
									<div className="item-icon"><FontAwesomeIcon icon={faBrain} /></div>
									<h3 className="item-heading">Inteligencia Artificial</h3>
								</div>
								<div className="item-desc">
									Comision responsable de promover el desarrollo tecnologico de inteligencia artificial y redes neuronales.
								</div>
							</div>
						</div>
		
					</div>
				</div>
			</section>

			{/*
			ESTA ES LA ORIGINAL
			
			<section  id="author-section" class="author-section section theme-bg-primary py-5">
				<div data-aos="fade-up" class="container py-3">
					<div class="author-profile text-center mb-5">
						<img class="author-pic" src={benja} alt="image" />
					</div>
					<h2 class="section-heading text-center text-white mb-3">PRESIDENTE</h2>
					<div class="author-bio single-col-max mx-auto">
						<h4 className='text-white text-center'>Benjamin Bernardo Cervantes Vega</h4>
						<p>Dir. Customer Success Purpura Analytics
							Asesor de la Comision de la Defensa Nacional
							Director del Instituto del Desarrollode la Sociedad del Conocimiento del Estado de Aguascalientes
							Socio Fundador Krahen Kapital
							Socio Fundador de Maker Center de Mexico
						</p>
						<div class="author-links text-center pt-4">
							<h5 class="text-white mb-4">Seguir</h5>
							<ul class="social-list list-unstyled">
								<li class="list-inline-item"><a href=""><FontAwesomeIcon icon={faTwitter} /></a></li>
								<li class="list-inline-item"><a href=""><FontAwesomeIcon icon={faInstagram} /></a></li>
								<li class="list-inline-item"><a href="https://www.linkedin.com/in/benjamincervantes/"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>

							</ul>
						</div>

					</div>

				</div>
			</section> */}
			{/*
			ESTA ES La 1ra opcion
			<section  id="author-section" class="author-section section theme-bg-primary py-5">
			<div data-aos="fade-up" class="container team">
				<div  class="container py-3">
					<div class="author-profile text-center mb-5">
						<img class="author-pic" src={benja} alt="image" />
					</div>
					<h2 class="section-heading text-center text-white mb-3">PRESIDENTE</h2>
					<div class="author-bio single-col-max mx-auto">
						<h4 className='text-white text-center'>Benjamin Bernardo Cervantes Vega</h4>
						<p>Dir. Customer Success Purpura Analytics
							Asesor de la Comision de la Defensa Nacional
							Director del Instituto del Desarrollode la Sociedad del Conocimiento del Estado de Aguascalientes
							Socio Fundador Krahen Kapital
							Socio Fundador de Maker Center de Mexico
						</p>
						<div class="author-links text-center pt-4">
							<h5 class="text-white mb-4">Seguir</h5>
							<ul class="social-list list-unstyled">
								<li class="list-inline-item"><a href=""><FontAwesomeIcon icon={faTwitter} /></a></li>
								<li class="list-inline-item"><a href=""><FontAwesomeIcon icon={faInstagram} /></a></li>
								<li class="list-inline-item"><a href="https://www.linkedin.com/in/benjamincervantes/"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>

							</ul>
						</div>

					</div>

				</div>
				<div  class="container py-3">
					<div class="author-profile text-center mb-5">
						<img class="author-pic" src={benja} alt="image" />
					</div>
					<h2 class="section-heading text-center text-white mb-3">PRESIDENTE</h2>
					<div class="author-bio single-col-max mx-auto">
						<h4 className='text-white text-center'>Benjamin Bernardo Cervantes Vega</h4>
						<p>Dir. Customer Success Purpura Analytics
							Asesor de la Comision de la Defensa Nacional
							Director del Instituto del Desarrollode la Sociedad del Conocimiento del Estado de Aguascalientes
							Socio Fundador Krahen Kapital
							Socio Fundador de Maker Center de Mexico
						</p>
						<div class="author-links text-center pt-4">
							<h5 class="text-white mb-4">Seguir</h5>
							<ul class="social-list list-unstyled">
								<li class="list-inline-item"><a href=""><FontAwesomeIcon icon={faTwitter} /></a></li>
								<li class="list-inline-item"><a href=""><FontAwesomeIcon icon={faInstagram} /></a></li>
								<li class="list-inline-item"><a href="https://www.linkedin.com/in/benjamincervantes/"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>

							</ul>
						</div>
					</div>

				</div>


				<div  class="container py-3">
					<div class="author-profile text-center mb-5">
						<img class="author-pic" src={benja} alt="image" />
					</div>
					<h2 class="section-heading text-center text-white mb-3">PRESIDENTE</h2>
					<div class="author-bio single-col-max mx-auto">
						<h4 className='text-white text-center'>Benjamin Bernardo Cervantes Vega</h4>
						<p>Dir. Customer Success Purpura Analytics
							Asesor de la Comision de la Defensa Nacional
							Director del Instituto del Desarrollode la Sociedad del Conocimiento del Estado de Aguascalientes
							Socio Fundador Krahen Kapital
							Socio Fundador de Maker Center de Mexico
						</p>
						<div class="author-links text-center pt-4">
							<h5 class="text-white mb-4">Seguir</h5>
							<ul class="social-list list-unstyled">
								<li class="list-inline-item"><a href=""><FontAwesomeIcon icon={faTwitter} /></a></li>
								<li class="list-inline-item"><a href=""><FontAwesomeIcon icon={faInstagram} /></a></li>
								<li class="list-inline-item"><a href="https://www.linkedin.com/in/benjamincervantes/"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>

							</ul>
						</div>

					</div>

				</div>
				</div>
			</section> 

			*/}


			{/*
Opcion Carrusel
*/}

			<section id="author-section" className="author-section section theme-bg-primary py-5">
				<Carousel data-aos="fade-up" className='container carru'
					arrows={false}

				>


					<div className="container py-3">
						<div className="author-profile text-center mb-5">
							<img className="author-pic" src={benja} alt="image" />
						</div>
						<h2 className="section-heading text-center text-white mb-3">PRESIDENTE</h2>
						<div class="author-bio single-col-max mx-auto">
							<h4 className='text-white text-center'>Benjamin Bernardo Cervantes Vega</h4>
							<p align="justify">Dir. Customer Success Purpura Analytics
								Asesor de la Comision de la Defensa Nacional
								Director del Instituto del Desarrollode la Sociedad del Conocimiento del Estado de Aguascalientes
								Socio Fundador Krahen Kapital
								Socio Fundador de Maker Center de Mexico.
							</p>
							<div className="author-links text-center pt-4">
								<h5 className="text-white mb-4">Seguir</h5>
								<ul className="social-list list-unstyled">
									<li className="list-inline-item"><a href=""><FontAwesomeIcon icon={faTwitter} /></a></li>
									<li className="list-inline-item"><a href=""><FontAwesomeIcon icon={faInstagram} /></a></li>
									<li className="list-inline-item"><a href="https://www.linkedin.com/in/benjamincervantes/"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>

								</ul>
							</div>
						</div>
					</div>
					<div className="container py-3">
						<div className="author-profile text-center mb-5">
							<img className="author-pic" src={jose} alt="image" />
						</div>
						<h2 className="section-heading text-center text-white mb-3">Director General</h2>
						<div className="author-bio single-col-max mx-auto">
							<h4 className='text-white text-center'>José de  Jesus Sánches</h4>
							<p align="justify" >
								Gerente Comercial en Inteligencia Patronal Legal, productor de TV, periodista, divulgador científico en radio,  coordinador de programas y proyectos culturales en el Consejo Nacional de Fomento Educativo.


							</p>
							<div className="author-links text-center pt-4">
								{/* <h5 className="text-white mb-4">Seguir</h5> */}
								{/* <ul className="social-list list-unstyled">
									<li className="list-inline-item"><a href=""><FontAwesomeIcon icon={faTwitter} /></a></li>
									<li className="list-inline-item"><a href=""><FontAwesomeIcon icon={faInstagram} /></a></li>
									<li className="list-inline-item"><a href="https://www.linkedin.com/in/benjamincervantes/"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>

								</ul> */}
							</div>

						</div>
					</div>




				</Carousel>

			</section>



			<div className="footer">

				<div className="footer-bottom text-center py-3">
					<div className="copyright">
						innovatia.mx
					</div>
				</div>

			</div>








		</div>
	)
}

export default Home