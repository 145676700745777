import React, { Fragment, useEffect, useRef, useState, } from 'react'
import { Link } from "react-router-dom";
import Aos from 'aos'
import axios from 'axios';
import Spiner from '../Components/Spiner'

import '../Pages/assets/css/uploader.css'
//import 'bulma/css/bulma.min.css';
import upload from '../Pages/assets/images/upload.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Route, Routes, useNavigate } from 'react-router-dom';


import { ToastContainer, toast } from 'react-toastify';
import MasterURL from '../api/api';

import 'react-toastify/dist/ReactToastify.css';
export default function Uploader({ 
postul,
masterid,
toggle,
settoggle,
fA,
habilidadesB,
JobE,
JobP,
JobFI,
JobFF,
nCurso,
fCurso,
lang,
langl
 }) {

 const baseURL = MasterURL+"addpostul";

 const notify = () => toast.success("Registro enviado con exito!");

  const hadleClose = () => {
    settoggle(false)
  }

  const navigate = useNavigate();

  function createPost() {

    let newlang  = ""

    for (let i = 0 ; i < langl.length ;i++){
      newlang += lang[i].toLowerCase()
      newlang += ","
      newlang += langl[i].toLowerCase()
      newlang += ","
    }

    notify()

    axios
      .post(baseURL, {
        idJob: masterid,
        nombre: postul.nombre.toLowerCase(),
        apellidos: postul.apellidos.toLowerCase(),
        curp: postul.curp,
        tel: postul.tel,
        domicilio: postul.domicilio.toLowerCase(),
        calle_Num: postul.calle_Num.toLowerCase(),
        col: postul.col.toLowerCase(),
        cp: postul.cp,
        lugarN: postul.lugarN.toLowerCase(),
        fechaN: postul.fechaN,
        edad: postul.edad,
        sexo: postul.sexo.toLowerCase(),
        linked: postul.linked,
        nacionalidad: postul.nacionalidad.toLowerCase(),
        Ecivil: postul.Ecivil.toLowerCase(),
        licencia: postul.licencia.toLowerCase(),
        fA:fA.filter(x=> x != "").toString().toLowerCase(),
        habilidadesB: habilidadesB.filter(x=> x != "").toString().toLowerCase(),
        jobE: JobE.toString().toLowerCase(),
        JobP:JobP.toString().toLowerCase(),
        JobFI: JobFI.toString().toLowerCase(),
        JobFF: JobFF.toString().toLowerCase(),
        nCurso: nCurso.toString().toLowerCase(),
        fCurso: fCurso.toString().toLowerCase(),
        lang: newlang,
        resume: postul.resume.toLowerCase(),
        email: postul.email.toLowerCase(),
        status:"na"
      })
      .then((res) => {
        //setPost(response.data);
        
        setTimeout(() => {
          // 👇 Redirects to about page, note the `replace: true`
          navigate('/bolsadetrabajo', { replace: true });
        }, 1300);
      });
  }


  if (toggle) {
    return (

      <div id="myModal" className="modal">
        <div className="modal-content">
          <div className='croz'>
            <FontAwesomeIcon className='iconchek' icon={faXmark} onClick={hadleClose} />
          </div>

          <img className='imgupload' src={upload} />
          
          <div>
            <h2>Una vez enviada tu información no podrá ser modificada.</h2>
            <p>¿Desea continuar?..</p>
          </div>

          <div className='btnsup'>

            <button className="btn btn-primary " onClick={hadleClose}>
              Cancelar
            </button>

            <button className="btn btn-secondary" onClick={createPost} >


              Enviar
            </button>


          </div>

        </div>

      </div>


    )
  }

  return (<></>)
}
