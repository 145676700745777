import React from 'react'
import mising from '../Pages/assets/images/404.svg'
import './assets/css/notfound.css'


export default function Mising() {
  return (
    <div className='notcont'>
      <div className='notcont2'>
      <img className ='notcontimg'src={mising}></img>
        <p>Page not found</p>
      </div>
    
    </div>
  )
}
