import React, { useRef, useState, useEffect, useContext } from 'react'
import useAuth from '../hooks/useAuth';

import './assets/css/login.css'
import logo from '../Pages/assets/images/lgo1.png'
import { Link, useNavigate, useLocation } from "react-router-dom";

import axios from '../api/axios';
const LOGIN_URL = '/auth';

export default function Login() {

    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/"

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');


    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const res = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });

            console.log(JSON.stringify(res?.data));

            const accessToken = res?.data?.token;
            const roles = res?.data?.roles;

            setAuth({ user, pwd, roles, accessToken });

            setUser('');
            setPwd('');

            
            navigate(from, { replace: true })
            //navigate("/dashRoot", { replace: true })
     


        } catch (err) {

            if (!err.response) {
                setErrMsg('No Server Response');
                return;
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Credentials');

            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }

            errRef.current.focus();
        }
    }

    return (


        <div className='log'>
            <form className='logincont'
                onSubmit={handleSubmit}
            >

                <div className='logocontainter4 mrgnbtm'>
                    <img className='loginlogo' src={logo}></img>
                </div>

                <p ref={errRef} className={errMsg ? "errmsg" :
                    "offscreen"} aria-live="assertive" >{errMsg}</p>

                <label htmlFor="username" className='inputsm'>USER <span>(obligatorio)</span></label>
                <input
                    className="form-control input-sm smal mrgnbtm"
                    id="username"
                    type="text"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                />

                <label htmlFor="password" className='inputsm'>Password<span>(obligatorio)</span></label>
                <input
                    className="form-control input-sm smal mrgnbtm"
                    id="password"
                    type="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                />

                <div className="btnlogin">
                    <div>
                        <button
                            className="btn btn-secondary ntnwdt"

                        >Sign In</button>
                    </div>
                </div>
            </form>

        </div>

    )
}
