import React from 'react'
import { Link } from "react-router-dom";

import notdounf from '../Pages/assets/images/404.svg'

export default function JobVisual({ data, filterdata, query, queryid }) {


    if (data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).length === 0) {
        return (null

        )
    } else {
        return (
            <>

                <div className='card3'>
                    <div className='card4' >
                        <div>
                            <div className='Card2Title'>{filterdata[queryid].nombreTrabajo}</div>
                        </div>

                        <div className='.Card2em'>{filterdata[queryid].nombreEmpresa}</div>
                        <div className='.Card2em'>{filterdata[queryid].ub}</div>
                        <div className='.Card2em'>
                            {filterdata[queryid].salario[0]}.00-{filterdata[queryid].salario[1]}.00/{filterdata[queryid].salario[2]} - {filterdata[queryid].tipo}

                        </div>
                        <div className='postulbot'>
                            <Link to="/form" className="btn btn-primary btn-submit">Postularse ahora</Link>
                        </div>
                    </div>

                    <div className='scrollable-div'>
                        <div className='cardinsider'>
                            <div>
                                <div className='Card2Title'>Detalles del empleo</div>
                            </div>
                            <div className='subs'>Salario</div>
                            {filterdata[queryid].salario[0]}.00-{filterdata[queryid].salario[1]}.00/{filterdata[queryid].salario[2]} - {filterdata[queryid].tipo}
                            <div className='subs'>Tipo de empleo</div>
                            {filterdata[queryid].tipo}

                        </div>

                        <div className='cardinsider'>
                            <div>
                                <div className='Card2Title'>Beneficios</div>
                                {
                                    filterdata[queryid].beneficios.map((benefit) =>
                                        <div className='benefits'>{benefit}</div>
                                    )
                                }
                            </div>
                        </div>

                        <div className='cardinsider'>
                            <div>
                                <div className='Card2Title'>Descripción completa del empleo</div>
                            </div>
                            {filterdata[queryid].fullDes}
                        </div>
                    </div>
                </div >

            </>
        )
    }
}
