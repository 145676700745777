import React from 'react'
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'

function DyInputLangua({ getter, setter, langl, setlangpl }) {

    //level
    const addFields2 = () => {
        let newfield = "B1"
        handleInputChange2([...langl, newfield])
    }

    const removeFields2 = (index) => {
        let data = [...langl];
        data.splice(index, 1)
        handleInputChange2(data);
    }
    const handleInputChange2 = useCallback(event => {
        setlangpl(event)
    }, [setlangpl])
    const handleFormChange2 = (index, event) => {
        let data = [...langl];
        data[index] = event.target.value;
        handleInputChange2(data);
    }


    //language

    const addFields = () => {
        let newfield = ""
        handleInputChange([...getter, newfield])
        addFields2()

    }

    const removeFields = (index) => {
        let data = [...getter];
        data.splice(index, 1)
        handleInputChange(data);
        removeFields2(index);

    }
    const handleInputChange = useCallback(event => {
        setter(event)
    }, [setter])

    const handleFormChange = (index, event) => {
        let data = [...getter];
        data[index] = event.target.value.slice(0, 20);
        handleInputChange(data);
    }

    return (
        <>
            <div class="form-group">
                <label for="inputsm" className='inputsm'>Agregar Idiomas <span></span></label>
                {
                    getter.map((frase, index) => (
                        <div className="dy-form-j">
                            <div className='dy-form-j2'>
                                <div className='inner-form'>
                                    <div style={{
                                        marginRight: '10px'
                                    }}><label for="inputsm" className='inputsm'><span>Idioma</span></label>
                                        <input key={index} class="form-control input-sm smal2" type="text" value={getter[index]} onChange={event => handleFormChange(index, event)} />
                                    </div>
                                    <div>
                                        <label for="inputsm" className='inputsm'><span>Nivel</span></label>

                                        <select className='input-sm smal2 form-select' value={langl[index]} onChange={event => handleFormChange2(index, event)}>
                                            <option value="B1">B1</option>
                                            <option value="B2">B2</option>
                                            <option value="C1">C1</option>
                                            <option value="C2">C2</option>
                                        </select>


                                    </div>
                                </div>

                            </div>
                            <div className="deleteicon">
                                <FontAwesomeIcon key={index} icon={faTrash} onClick={e => removeFields(index)} />
                            </div>
                        </div>
                    ))
                }
                <div className="addFases">
                    <FontAwesomeIcon icon={faCirclePlus} onClick={addFields} />
                </div>
            </div>
        </>
    )
}

export default DyInputLangua