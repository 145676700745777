
import React from 'react'

import unauth  from './assets/images/unauth.svg'

export default function Unauth() {
  return (
    <div className='notcont'>
    <div className='notcont2'>
    <img className ='notcontimg'src={unauth}></img>
      <p>Without authorization</p>
    </div>
  
  </div>
  )
}
