import { display } from '@mui/system';
import React from 'react'
import { useState, useEffect, useContext } from "react";

import { useNavigate, Link, useLocation } from "react-router-dom";
import notdounf from '../Pages/assets/images/404.svg'
import AuthContext from "../context/AuthProvider";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function PostulVisual({ postul, setPostul }) {

    const [searchid, setSearchid] = useState(null);
    const { setAuth } = useContext(AuthContext);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setSearchid(postul.idPostulante);
    }, [postul])

    const notify2 = () => toast.success("Se modifico la informacion correctamente.");
    const notify = () => toast.error("Error en el Server.");

    const reload = () => {
        notify2();
    
        navigate('/users', { state: { from: location }, replace: true });
    }


    const handleAcept = (id) => {

        const PostJob = async () => {
            try {
                const response = await axiosPrivate.put(`/poostulacept/${id}`, {
                    status: "aceptado"
                });
                setTimeout(() => {
                    reload();
                }, 200);


            } catch (err) {

                if (err.code != "ERR_CANCELED")
                    notify();
                //tiempo de espera para que el usuario lea el mensaje
                setTimeout(() => {

                    navigate('/login', { state: { from: location }, replace: true });
                    logout();

                }, 2000);
            }

        }

        PostJob();


    }


    const handleReject = (id) => {

        const PostJob = async () => {
            try {
                const response = await axiosPrivate.put(`/poostulreject/${id}`, {
                    status: "rechazado"
                });
                setTimeout(() => {
                    reload();
                }, 200);


            } catch (err) {

                if (err.code != "ERR_CANCELED")
                    notify();
                //tiempo de espera para que el usuario lea el mensaje
                setTimeout(() => {

                    navigate('/login', { state: { from: location }, replace: true });
                    logout();

                }, 2000);
            }

        }

        PostJob();

    }


    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        navigate('/admin');
    }

    if (postul === null) {
        return (
            <div>
                cargando
            </div>
        )
    }
 
    return (
        <>


            <ToastContainer position="top-center" />


            <div className='card32' style={{
                padding: '0px',
                margin: '0px',
            }}>
                <div className='card4' style={
                    {
                        marginBottom: '0px',
                        height: 'auto',
                        overflow: 'visible',
                    }
                }>
                    <div>
                        <div className='Card2Title'> Nombre: {postul.nombre}</div>
                    </div>

                    <div className='.Card2em'>Apellidos: {postul.apellidos}</div>
                    <div className='.Card2em'>Tel: {postul.tel}</div>
                    <div className='.Card2em'>E-mail: {postul.email}</div>
                    <div className='.Card2em'>Linked-In: {postul.linked}</div>
                    <div className='.Card2em'>Status: {postul.status}</div>

                    <div style={
                        { display: 'flex' }
                    }>

                        <div className="col-12 col-md-auto" style={{
                            marginRight: '10px',
                        }}>
                            <button className='addJobs' id={searchid} onClick={(e) => { handleAcept(e.currentTarget.id) }} >Aceptar</button>
                        </div>
                        <div className="col-12 col-md-auto">
                            <button className="addJobs" style={{
                                backgroundColor: 'red',
                            }} href="#benefits-section" id={searchid} onClick={(e) => { handleReject(e.currentTarget.id) }}>Rechazar</button>
                        </div>
                    </div>


                </div>

                <div className='scrollable-div'>
                    <div className='cardinsider'>
                        <div>
                            <div className='Card2Title'>Información Personal</div>

                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Curp:</span> {postul.curp}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Domicilio:</span> {postul.domicilio}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Calle y Numero:</span> {postul.calle_Num}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Colonia:</span> {postul.col}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>CP:</span> {postul.cp}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Lugar de Nacimiento:</span> {postul.lugarN}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Fecha de Nacimineto:</span> {postul.fechaN}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Edad:</span> {postul.edad}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Sexo:</span> {postul.sexo}</div>
            
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Nacionalidad:</span> {postul.nacionalidad}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Estado Civil:</span> {postul.Ecivil}</div>
                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Licencia:</span> {postul.licencia}</div>
                        </div>

                    </div>

                    <div className='cardinsider'>
                        <div>
                            <div className='Card2Title'>Formación académica</div>
                            {
                                postul.fA.map((f) =>
                                    <div className='benefits'>{f}</div>
                                )
                            }
                        </div>
                    </div>

                    <div className='cardinsider'>
                        <div>
                            <div className='Card2Title'>Habilidades blandas</div>
                            {
                                postul.habilidadesB.map((f) =>
                                    <div className='benefits'>{f}</div>
                                )
                            }
                        </div>
                    </div>

                    <div className='cardinsider'>
                        <div>
                            <div className='Card2Title'>Experiencia Laboral</div>
                            {
                                postul.jobE.map((f, index) => (
                                    <div className='benefits'>
                                        <div style={{
                                            justifyContent: 'start'
                                        }}>

                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Nombre Empresa:</span> {f}</div>
                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Nombre Puesto:</span> {postul.JobP[index]}</div>
                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Fecha Inicio:</span> {postul.JobFI[index]}</div>
                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Fecha Fin:</span> {postul.JobFF[index]}</div>


                                        </div>

                                    </div>
                                )
                                )

                            }
                        </div>
                    </div>


                    <div className='cardinsider'>
                        <div>
                            <div className='Card2Title'>Capacitación o cursos</div>
                            {
                                postul.nCurso.map((f, index) => (
                                    <div className='benefits'>
                                        <div style={{
                                            justifyContent: 'start'
                                        }}>

                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Nombre Curso:</span> {f}</div>
                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Fecha:</span> {postul.fCurso[index]}</div>

                                        </div>

                                    </div>
                                )
                                )

                            }
                        </div>
                    </div>


                    <div className='cardinsider'>
                        <div>
                            <div className='Card2Title'>Idiomas y nivel</div>
                            {
                                postul.lang.map((f, index) => (
                                    <div className='benefits'>
                                        <div style={{
                                            justifyContent: 'start'
                                        }}>

                                            <div> {f}</div>


                                        </div>

                                    </div>
                                )
                                )

                            }
                        </div>
                    </div>

                    <div className='cardinsider'>
                        <div>
                            <div className='Card2Title'>Resumen Profecional</div>
                            {postul.resume}
                        </div>

                    </div>
                </div>
            </div >

        </>
    )

}
