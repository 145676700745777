import React from 'react'
//import  useEfect and useState from react
import { useEffect, useState, useContext } from 'react'
import { useNavigate, Link, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import logo from '../Pages/assets/images/lgo1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import DynamicInputSmall from './DynamicInputSmall';


import useAxiosPrivate from "../hooks/useAxiosPrivate";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { es } from 'faker/lib/locales';


export default function JobAdder({ isActive, setisActive }) {

	//create a state for the jobs 
	const { setAuth } = useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate()
	const controller = new AbortController();

	const [job, setJob] = useState({

		nombreEmpresa: "",
		nombreTrabajo: "",
		tipo: "Tiempo Completo",
		horas: "",
		salario: "",
		des: "",
		beneficios: "",
		fullDes: "",
		modalidad: "Presencial",
		ub: "",

	})
	const [salari, setsalari] = useState({
		one: "",
		two: "",
		time: "mes",
	})

	const [ben, setben] = useState(["", "", ""])

	const notify = () => toast.error("Todos los campos son obligatorios.");
	const notify2 = () => toast.error("No se envio la Informacion error en el Server.");
	const notify3 = () => toast.success("Se guardo la informacion correctamente.");

	const handleJobPost = (event) => {

		const PostJob = async () => {
			try {
				const response = await axiosPrivate.post('/task', {
					nombreEmpresa: job.nombreEmpresa,
					nombreTrabajo: job.nombreTrabajo,
					tipo: job.tipo,
					horas: job.horas,
					salario: salari.one + "," + salari.two + "," + salari.time,
					des: job.des,
					beneficios: ben.filter(x => x != "").toString().toLowerCase(),
					fullDes: job.fullDes,
					modalidad: job.modalidad,
					ub: job.ub,

					signal: controller.signal
				});

				if (response.status == 200) {
					notify3();
				} else {
					notify3();
				}

				setTimeout(() => {
					navigate('/registro');
				}, 2000);


			} catch (err) {

				if (err.code != "ERR_CANCELED")
					notify2();
				//tiempo de espera para que el usuario lea el mensaje
				setTimeout(() => {

					navigate('/login', { state: { from: location }, replace: true });
					logout();

				}, 2000);
			}

		}



		if (job.nombreEmpresa == "" || job.nombreTrabajo == "" || job.horas == "" || job.des == "" || job.modalidad == "" || job.fullDes == "" || job.ub == "" || salari.one == "" || salari.two == "" || salari.time == "") {
			notify();
			console.log("si entro")

		} else {
			PostJob();
		}
	}




	const handleFormInfoChange = (event) => {

		if (event.target.name == "fullDes") {
			setJob({
				...job,
				[event.target.name]: event.target.value.slice(0, 1510)
			})
		} else if (event.target.name == "des") {
			setJob({
				...job,
				[event.target.name]: event.target.value.slice(0, 500)
			})
		}
		else {
			setJob({
				...job,
				[event.target.name]: event.target.value.slice(0, 50)
			})
		}
	}
	const handleFormInfoChange2 = (event) => {
		setsalari({
			...salari,
			[event.target.name]: event.target.value.slice(0, 50)
		})
	}


	const logout = async () => {
		// if used in more components, this should be in context 
		// axios to /logout endpoint 
		setAuth({});
		navigate('/admin');
	}

	const handleClick = event => {
		// 👇️ toggle isActive state on click
		setisActive(current => !current);
	};

	const handleBack = e => {

		navigate('/registro');

	}



	return (
		<>
			<ToastContainer

				position="top-center" />
			<nav class={isActive ? "sidebar open" : "sidebar close"}>
				<header>
					<div class="image-text">
						<span class="image">
							<img src={logo} alt="" />
						</span>

						<div class="text logo-text">
							<span class="name">Innovatia</span>
						</div>
					</div>

					<i class='bx bx-chevron-right toggle' onClick={handleClick}></i>
				</header>

				<div class="menu-bar">
					<div class="menu">

						<ul class="menu-links">



							<li class="nav-link">
								<Link to="/admin">
									<i class='bx bx-home-alt icon' ></i>
									<span class="text nav-text">Dashboard </span>
								</Link>
							</li>


							<li class="nav-link" style={{
								backgroundColor: '#695CFE',
								borderRadius: '6px',
								color: 'white',
							}}>
								<Link to="/registro">
									<i style={{
										color: 'white',
									}} class='bx bx-bar-chart-alt-2 icon' ></i>
									<span style={
										{ color: 'white' }
									} class="text nav-text">Registro</span>
								</Link>
							</li>


							<li class="nav-link">
								<Link to="/users">
									<i class='bx bx-bell icon'></i>
									<span class="text nav-text">Postulantes</span>
								</Link>
							</li>

							<li class="nav-link">
								<Link to='/config'>
									<i class='bx bx-pie-chart-alt icon' ></i>
									<span class="text nav-text">Config</span>
								</Link>
							</li>


						</ul>
					</div>

					<div class="bottom-content">
						<li class="">
							<a onClick={logout}>
								<i class='bx bx-log-out icon' ></i>
								<span class="text nav-text">Logout</span>
							</a>
						</li>
					</div>
				</div>

			</nav>


			<section class="homes">
				<div class="text" style={{
					display: "flex",
					felxDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100%"
				}}>
					<div className='registercontainer' style={
						{
							overflow: 'scroll',
							overflowX: 'hidden',
						}
					}>



						<div className='formTitle' style={{ width: '100%' }}>

							<div style={{
								display: 'flex',
								alignItems: 'center',
								gap: '20px',
								width: '40rem'
							}}>
								<div className='back' style={{ backgroundColor: '#695CFE', }} >
									<FontAwesomeIcon icon={faArrowLeft} onClick={handleBack} />
								</div>

								<span className="formTitle" style={{ color: '#695CFE', }}>Registrar Trabajo</span>
							</div>

							<div style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'flex-end',
							}}>


								<FontAwesomeIcon icon={faFloppyDisk} className="saveJob" style={{
									padding: '0px',
									margin: '0px',
									color: '#695CFE',
									fontSize: '1.4rem',
								}} onClick={handleJobPost} />


							</div>

						</div>
						<div style={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '20px',
						}}>

							<div>
								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Nombre Empresa<span>(obligatorio)</span></label>
									<input class="form-control input-sm smal" name="nombreEmpresa" type="text" value={job.nombreEmpresa} onChange={handleFormInfoChange} />
								</div>


								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Nombre Trabajo<span>(obligatorio)</span></label>
									<input class="form-control input-sm smal" name="nombreTrabajo" type="text" value={job.nombreTrabajo} onChange={handleFormInfoChange} />
								</div>

								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Tipo<span>(obligatorio)</span></label>
									<select className='input-sm smal2 form-select' name="tipo" type="text" value={job.tipo} onChange={handleFormInfoChange}>
										<option value="Tiempo Completo">Tiempo Completo</option>
										<option value="Medio Tiempo">Medio Tiempo</option>
										<option value="otro">otro</option>
									</select>
								</div>

								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Modalidad<span>(obligatorio)</span></label>
									<select className='input-sm smal2 form-select' name="modalidad" type="text" value={job.modalidad} onChange={handleFormInfoChange}>
										<option value="Presencia">Presencial </option>
										<option value="Remoto">Remoto</option>
										<option value="Híbrido">Híbrido</option>
									</select>
								</div>



								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Horas / Día<span>(obligatorio)</span></label>
									<input class="form-control input-sm smal" name="horas" id="inputsm" type="number" value={job.horas} onChange={handleFormInfoChange} />
								</div>


								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Salario<span>(obligatorio)</span></label>
									<div style={{
										display: 'flex',
										alignItems: 'center',
									}}>
										<input class="form-control input-sm smal2" name="one" id="inputsm" type="number" value={salari.one} onChange={handleFormInfoChange2} />
										-
										<input class="form-control input-sm smal2" name="two" id="inputsm" type="number" value={salari.two} onChange={handleFormInfoChange2} />
										/
										<select className='input-sm smal2 form-select' name="time" value={salari.time} onChange={handleFormInfoChange2}>
											<option value="mes">mes</option>
											<option value="quincena">quiencena</option>
											<option value="día">día</option>
										</select>

									</div>

								</div>

								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Breve Descripción<span>(obligatorio)</span></label>
									<input class="form-control input-sm " name="des" id="inputsm" type="text" value={job.des} onChange={handleFormInfoChange} />
								</div>


								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Ubicación<span>(obligatorio)</span></label>
									<input class="form-control input-sm smal" name="ub" id="inputsm" type="text" value={job.ub} onChange={handleFormInfoChange} />
								</div>


							</div>

							<div style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'flex-start',
							}}>
								<div class="form-group" style={{
									margin: '0px',
								}}>
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Descripción Completa<span>(obligatorio 300w max) </span></label>
									<textarea class="form-control input-sm bigger" id="inputsm" name="fullDes" type="text" value={job.fullDes} onChange={handleFormInfoChange} />
								</div>
								<DynamicInputSmall getter={ben} setter={setben} />
							</div>



						</div>








					</div>
				</div>
				<div style={
					{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}
				} className="footer-bottom text-center ">
					<div className="copyright">
						innovatia.mx
					</div>
				</div>
			</section>



		</>
	)
}
