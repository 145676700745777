import React from 'react'
import Card from '../../Components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useContext } from "react";

import { useNavigate, Link, useLocation } from "react-router-dom";
import JobsList from '../../Components/JobsList'


import AuthContext from "../../context/AuthProvider";
import { useState, useEffect } from 'react';
//import use state and use effect

import useAxiosPrivate from "../../hooks/useAxiosPrivate";



//chart js
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import faker from 'faker';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
    bezierCurve: true,
    // remove vertical lines
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
    }

};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const labels1 = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const options2 = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
        },

    }
};



const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: ' #695CFE',
            backgroundColor: ' #695CFE',
            tension: .3,
            //fill the area under the line chart js 2 
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(255, 0, 0, 0.3)"
            }
        },

    ],
};








const datalist = ["trabajo1", "trabajo2", "trabajo3", "trabajo4", "trabajo5", "trabajo6", "trabajo7", "trabajo8 ", "t9"]

export default function Dashboard() {

    const [dataapi, setDataapi] = React.useState(null);

    const { setAuth } = useContext(AuthContext);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();







    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/dash', {
                    signal: controller.signal
                });

                console.log(response.data);
                isMounted && setDataapi(response.data)


            } catch (err) {
                //console.error(err)

                if (err.code != "ERR_CANCELED")
                    navigate('/login', { state: { from: location }, replace: true });

            }
        }

        // setIterval to call getUsers every 2min 120000)



        getUsers();
        const interval = setInterval(getUsers, 120000);

        return () => {
            isMounted = false;
            controller.abort();
            clearInterval(interval);
        }
    }, [])










    if (dataapi == null) {
        return <div>Loading...</div>
    }


    const data3 = {
        labels: ['Rechazados', 'Aceptados'],
        datasets: [
            {
                label: '# of Votes',
                data: [dataapi.rejectedPostul, dataapi.aceptedPostul],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };



    const data2 = {
        labels: dataapi.mostPopularJob.map((item) => item.nombreTrabajo),
        datasets: [
            {
                label: 'Dataset 1',
                data: dataapi.mostPopularJob.map((item) => item.postulate_count),
                backgroundColor: '#695CFE',
            },
        ],
    };


    const data = {
        labels: dataapi.rPostulinTime.map((item) => item.date),
        datasets: [
            {
                label: 'Dataset 1',
                data: dataapi.rPostulinTime.map((item) => item.countPostulantes),
                borderColor: ' #695CFE',
                backgroundColor: ' #695CFE',
                tension: .3,
                //fill the area under the line chart js 2 
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: "rgba(255, 0, 0, 0.3)"
                }
            },

        ],
    };


    const dataj = {
        labels: dataapi.jobInTime.map((item) => item.date),
        datasets: [
            {
                label: 'Dataset 1',
                data: dataapi.jobInTime.map((item) => item.countJob),
                borderColor: ' #695CFE',
                backgroundColor: ' #695CFE',
                tension: .3,
                //fill the area under the line chart js 2 
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: "rgba(255, 0, 0, 0.3)"
                }
            },

        ],
    };






    return (
        <div>
            <div>Dashboard</div>
            <div style={
                {
                    display: 'flex',
                    gap: '20px',
                }
            }>
                <Card name="Trabajos Registrados" data={dataapi.registerJobs} icon={<FontAwesomeIcon className='iconchek' icon={faSuitcase} />} />
                <Card name="Postulantes Registrados" data={dataapi.resgisterPostulantes} icon={<FontAwesomeIcon className='iconchek' icon={faUser} />} />
                <Card name="Postulantes Aceptados" data={dataapi.aceptedPostul} icon={<FontAwesomeIcon className='iconchek' icon={faCheckCircle} />} />
                <Card name="Postulantes Rechazados" data={dataapi.rejectedPostul} icon={<FontAwesomeIcon className='iconchek' icon={faXmark} />} />

            </div>


            <div className='chartscontainer'>

                <div className='cardChart'>
                    <div className='cardDashTitle' style={{
                        marginBottom: '10px',
                    }}>Trabajo mas postulado</div>
                    <div style={{
                        height: '100%',
                    }}>
                        <div style={{
                            height: '50%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Bar options={options2} data={data2} />
                        </div>
                        <div style={{
                            maxHeight: '100%',
                        }}>
                            <JobsList data={dataapi.mostPopularJob} />
                        </div>

                    </div>


                </div>

                <div className='cardChart2'>
                    <div className='cardDashTitle' style={{
                        marginBottom: '10px',
                    }}>Postulaciones a lo largo del tiempo</div>
                    <Line options={options} data={data} />
                </div>


            </div>




            <div className='chartscontainer'>

                <div className='cardChart2'>
                    <div className='cardDashTitle' style={{
                        marginBottom: '10px',
                    }}>Registro de trabajos a lo largo del Tiempo</div>
                    <Line options={options} data={dataj} />
                </div>
                <div className='cardChart'>
                    <div className='cardDashTitle' style={{
                        marginBottom: '10px',
                    }}>Postulaciones</div>


                    <Pie className='pieDiv' data={data3} />


                </div>



            </div>


        </div>

    )
}
