import React from 'react'
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { useState, useEffect } from 'react';
//import use state and use effect

import useAxiosPrivate from "../../hooks/useAxiosPrivate";



import '../../Pages/assets/css/DashRoot.css'
import logo from '../../Pages/assets/images/lgo1.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { faCross } from '@fortawesome/free-solid-svg-icons'


import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';




import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};




export default function Registro({ isActive, setisActive, editid, seteditid }) {



    const [users, setUsers] = useState(null);
    const { setAuth } = useContext(AuthContext);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const notify = () => toast.error("No se elimino la Informacion error en el Server.");
    const notify2 = () => toast.success("Se elimino la informacion correctamente.");



    ///api call


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/jobs', {
                    signal: controller.signal
                });

                console.log(response.data);
                isMounted && setUsers(response.data)


            } catch (err) {
                //console.error(err)

                if (err.code != "ERR_CANCELED")
                    navigate('/login', { state: { from: location }, replace: true });

            }
        }
        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])


    const reload = () => {
        notify2();
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/jobs', {
                    signal: controller.signal
                });

                console.log(response.data);
                isMounted && setUsers(response.data)


            } catch (err) {
                //console.error(err)

                if (err.code != "ERR_CANCELED")
                    navigate('/login', { state: { from: location }, replace: true });

            }
        }
        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }





    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const rows = [

        {
            idJob: 1,
            nombreEmpresa: "empresa 1",
            nombreTrabajo: "trabajo1",
            des: "este es el trabajo 1",
            date: "1/13/2023"
        }
        ,
        {
            idJob: 2,
            nombreEmpresa: "empresa 2",
            nombreTrabajo: "trabajo2",
            des: "este es el trabajo 2",
            date: "1/13/2023"
        }
        ,
        {
            idJob: 3,
            nombreEmpresa: "empresa 3",
            nombreTrabajo: "trabajo3",
            des: "este es el trabajo 3",
            date: "1/13/2023"
        }
        ,
        {
            idJob: 4,
            nombreEmpresa: "empresa 4",
            nombreTrabajo: "trabajo4",
            des: "este es el trabajo 4",
            date: "1/13/2023"
        }
        ,
        {
            idJob: 5,
            nombreEmpresa: "empresa 5",
            nombreTrabajo: "trabajo5",
            des: "este es el trabajo 5",
            date: "1/13/2023"
        },
        {
            idJob: 6,
            nombreEmpresa: "empresa 6",
            nombreTrabajo: "trabajo6",
            des: "este es el trabajo 6",
            date: "1/13/2023"
        },
        {
            idJob: 7,
            nombreEmpresa: "empresa 7",
            nombreTrabajo: "trabajo7",
            des: "este es el trabajo 7",
            date: "1/13/2023"
        },
        {
            idJob: 8,
            nombreEmpresa: "empresa 8",
            nombreTrabajo: "trabajo8",
            des: "este es el trabajo 8",
            date: "1/13/2023"
        },
        {
            idJob: 9,
            nombreEmpresa: "empresa 9",
            nombreTrabajo: "trabajo9",
            des: "este es el trabajo 9",
            date: "1/13/2023"
        },
        {
            idJob: 10,
            nombreEmpresa: "empresa 10",
            nombreTrabajo: "trabajo10",
            des: "este es el trabajo 10",
            date: "1/13/2023"
        },
        {
            idJob: 11,
            nombreEmpresa: "empresa 11",
            nombreTrabajo: "trabajo11",
            des: "este es el trabajo 11",
            date: "1/13/2023"
        },
        {
            idJob: 12,
            nombreEmpresa: "empresa 12",
            nombreTrabajo: "trabajo12",
            des: "este es el trabajo 12",
            date: "1/13/2023"
        },



    ]



    const columns = [
        { id: 'idJob', label: 'id', minWidth: 70 },

        { id: 'nombreEmpresa', label: 'Empresa', minWidth: 170 },
        {
            id: 'nombreTrabajo',
            label: 'Trabajo',
            minWidth: 170,

        },
        {
            id: 'des',
            label: 'Des',
            minWidth: 170,

        }

        ,
        {
            id: 'date',
            label: 'Fecha',
            minWidth: 170,

        },

        {
            id: 'mod',
            label: 'Modalidad',
            minWidth: 170,

        },

        {
            id: 'acc',
            label: 'Acciones',
            minWidth: 170,

        },
    ];


    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setisActive(current => !current);
    };


    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        navigate('/admin');
    }


    const deleteJob = async (id) => {
        // if used in more components, this should be in context

        try {
            await axiosPrivate.delete(`/job/${id}`).then((res) => {
                reload();


            });
        } catch (err) {
            notify();
        }
    }


    const handleEdit = (id) => {
        seteditid(id);
        navigate('/editJob', { state: { from: location }, replace: false });

    }
    const handleVIew2 = (id) => {
        seteditid(id);
        navigate('/Jcomp', { state: { from: location }, replace: false });
    }

    if (users == null) {
        return (
            <>
                <ToastContainer

                    position="top-center" />
                <nav class={isActive ? "sidebar open" : "sidebar close"}>
                    <header>
                        <div class="image-text">
                            <span class="image">
                                <img src={logo} alt="" />
                            </span>

                            <div class="text logo-text">
                                <span class="name">Innovatia</span>
                            </div>
                        </div>

                        <i class='bx bx-chevron-right toggle' onClick={handleClick}></i>
                    </header>

                    <div class="menu-bar">
                        <div class="menu">

                            <ul class="menu-links">



                                <li class="nav-link">
                                    <Link to="/admin">
                                        <i class='bx bx-home-alt icon' ></i>
                                        <span class="text nav-text">Dashboard </span>
                                    </Link>
                                </li>


                                <li class="nav-link" style={{
                                    backgroundColor: '#695CFE',
                                    borderRadius: '6px',
                                    color: 'white',
                                }}>
                                    <Link to="/registro">
                                        <i style={{
                                            color: 'white',
                                        }} class='bx bx-bar-chart-alt-2 icon' ></i>
                                        <span style={
                                            { color: 'white' }
                                        } class="text nav-text">Registro</span>
                                    </Link>
                                </li>


                                <li class="nav-link">
                                    <Link to="/users">
                                        <i class='bx bx-bell icon'></i>
                                        <span class="text nav-text">Postulantes</span>
                                    </Link>
                                </li>

                                <li class="nav-link">
                                    <Link to='/config'>
                                        <i class='bx bx-pie-chart-alt icon' ></i>
                                        <span class="text nav-text">Config</span>
                                    </Link>
                                </li>


                            </ul>
                        </div>

                        <div class="bottom-content">
                            <li class="">
                                <a onClick={logout}>
                                    <i class='bx bx-log-out icon' ></i>
                                    <span class="text nav-text">Logout</span>
                                </a>
                            </li>
                        </div>
                    </div>

                </nav>
                <section class="homes">

                </section>


            </>
        )
    }

    return (
        <>

            <ToastContainer position="top-center" />

            <nav class={isActive ? "sidebar open" : "sidebar close"}>
                <header>
                    <div class="image-text">
                        <span class="image">
                            <img src={logo} alt="" />
                        </span>

                        <div class="text logo-text">
                            <span class="name">Innovatia</span>
                        </div>
                    </div>

                    <i class='bx bx-chevron-right toggle' onClick={handleClick}></i>
                </header>

                <div class="menu-bar">
                    <div class="menu">

                        <ul class="menu-links">



                            <li class="nav-link">
                                <Link to="/admin">
                                    <i class='bx bx-home-alt icon' ></i>
                                    <span class="text nav-text">Dashboard </span>
                                </Link>
                            </li>


                            <li class="nav-link" style={{
                                backgroundColor: '#695CFE',
                                borderRadius: '6px',
                                color: 'white',
                            }}>
                                <Link to="/registro">
                                    <i style={{
                                        color: 'white',
                                    }} class='bx bx-bar-chart-alt-2 icon' ></i>
                                    <span style={
                                        { color: 'white' }
                                    } class="text nav-text">Registro</span>
                                </Link>
                            </li>


                            <li class="nav-link">
                                <Link to="/users">
                                    <i class='bx bx-bell icon'></i>
                                    <span class="text nav-text">Postulantes</span>
                                </Link>
                            </li>

                            <li class="nav-link">
                                <Link to='/config'>
                                    <i class='bx bx-pie-chart-alt icon' ></i>
                                    <span class="text nav-text">Config</span>
                                </Link>
                            </li>


                        </ul>
                    </div>

                    <div class="bottom-content">
                        <li class="">
                            <a onClick={logout}>
                                <i class='bx bx-log-out icon' ></i>
                                <span class="text nav-text">Logout</span>
                            </a>
                        </li>
                    </div>
                </div>

            </nav>

            <section class="homes">
                <div class="text" style={{
                    display: "flex",
                    felxDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%"
                }}>
                    <div className='registercontainer'>

                        <div style={{
                            display: "flex",
                            justifyContent: 'flex-end'
                        }}>
                            <Link className='addJobs' to='/addJob'>+ Agregar Trabajos</Link>
                        </div>


                        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}

                                    </TableRow>
                                </TableHead>
                                <TableBody>



                                    {(users.length > 0
                                        ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : users
                                    ).map((row, idx) => {
                                        return (
                                            <TableRow key={idx} hover  >

                                                <TableCell align={columns[0].align} >
                                                    {row.idJob}
                                                </TableCell>
                                                <TableCell align={columns[1].align}>
                                                    {row.nombreEmpresa}
                                                </TableCell>
                                                <TableCell align={columns[2].align}>
                                                    {row.nombreTrabajo}
                                                </TableCell>
                                                <TableCell align={columns[3].align}>
                                                    {row.des.slice(0, 150)}...
                                                </TableCell>


                                                <TableCell align={columns[4].align}>
                                                    {row.date.split("T")[0]}
                                                </TableCell>

                                                <TableCell align={columns[5].align}>
                                                    {row.modalidad}
                                                </TableCell>
                                                <TableCell align={columns[6].align}>
                                                    <FontAwesomeIcon icon={faPencil} id={row.idJob} className="saveJob" style={
                                                        {
                                                            marginRight: '20px',
                                                            color: '#695CFE',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={(e) => { handleEdit(e.currentTarget.id) }}
                                                    />


                                                    <FontAwesomeIcon icon={faEye} id={row.idJob} className="saveJob" style={
                                                        {
                                                            marginRight: '20px',
                                                            color: 'gray',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={(e) => { handleVIew2(e.currentTarget.id) }}
                                                    />
                                                    <FontAwesomeIcon icon={faTrash} id={row.idJob} className="saveJob" style={{
                                                        color: 'red',
                                                        fontSize: '20px'
                                                    }}
                                                        onClick={(e) => { deleteJob(e.currentTarget.id) }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: users.length }]}
                                            count={users.length}

                                            rowsPerPage={rowsPerPage}
                                            page={page}

                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}

                                        />


                                    </TableRow>

                                </TableFooter>





                            </Table>
                        </TableContainer>



                    </div>
                </div>
                <div style={
                    {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }
                } className="footer-bottom text-center ">
                    <div className="copyright">
                        innovatia.mx
                    </div>
                </div>
            </section>




        </>)
}
