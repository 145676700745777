import React from 'react'
import Users from '../../Components/Users'
import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { useLocation } from "react-router-dom";
//import use state and use effect
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import JobVisualOne from '../../Components/JobVisualOne';
import PostulVisual from '../../Components/PostulVisual';

import '../../Pages/assets/css/DashRoot.css'
import logo from '../../Pages/assets/images/lgo1.png'



export default function Comparator({ isActive, setisActive, editid, seteditid, postulid, setpostulid }) {

  const { setAuth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(null);
  const [job, setJob] = useState(null);



  useEffect(() => {

    const getpostul = async () => {
      try {
        const response = await axiosPrivate.get(`/postul/${editid}`);

        setUser(response.data);

        console.log(response.data);
      } catch (err) {
        //console.error(err)
        if (err.code != "ERR_CANCELED")
          navigate('/login', { state: { from: location }, replace: true });

      }
    }

    getpostul();

  }, [])


  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setisActive(current => !current);

  };

  const logout = async () => {
    // if used in more components, this should be in context 
    // axios to /logout endpoint 
    setAuth({});
    navigate('/admin');
  }



  if (user == null) {
    return null
  }

  return (
    <>

      <nav key={user.idJob} class={isActive ? "sidebar open" : "sidebar close"}>
        <header>
          <div class="image-text">
            <span class="image">
              <img src={logo} alt="" />
            </span>

            <div class="text logo-text">
              <span class="name">Innovatia</span>
            </div>
          </div>

          <i class='bx bx-chevron-right toggle' onClick={handleClick}></i>
        </header>

        <div class="menu-bar">
          <div class="menu">

            <ul class="menu-links">
              <li class="nav-link">
                <Link to="/admin">
                  <i class='bx bx-home-alt icon'></i>
                  <span class="text nav-text  ">Dashboard</span>
                </Link>
              </li>

              <li class="nav-link">
                <Link to="/registro">
                  <i class='bx bx-bar-chart-alt-2 icon' ></i>
                  <span class="text nav-text">Registro </span>
                </Link>
              </li>


              <li class="nav-link" style={{
                backgroundColor: '#695CFE',
                borderRadius: '6px',
                color: 'white',
              }}>
                <Link to="/users">
                  <i style={{
                    color: 'white',
                  }} class='bx bx-bell icon  ' ></i>
                  <span style={
                    { color: 'white' }
                  } class="text nav-text">Postulantes</span>
                </Link>
              </li>



              <li class="nav-link">
                <Link to='/config'>
                  <i class='bx bx-pie-chart-alt icon' ></i>
                  <span class="text nav-text">Config</span>
                </Link>
              </li>
            </ul>
          </div>

          <div class="bottom-content">
            <li class="">
              <a onClick={logout}>
                <i class='bx bx-log-out icon' ></i>
                <span class="text nav-text">Logout</span>
              </a>
            </li>
          </div>
        </div>

      </nav>

      <section class="homes" style={
        { height: "50rem" }
      }>
        <div class="text" >
          <div class="registercontainer2" style={
            {
              display: "flex",
              justifyContent: "space-evenly",
            }
          }>


            <JobVisualOne job={user} setjob={setJob} />
            <PostulVisual postul={user} setPostul={setJob} />




          </div>
        </div>
        <div style={
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }
        } className="footer-bottom text-center ">
          <div className="copyright">
            innovatia.mx
          </div>
        </div>
      </section>

    </>
  )
}
