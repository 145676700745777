import React from 'react'

import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { display } from '@mui/system';
//https://www.youtube.com/watch?v=jn-iq1dKB38


function DyInputCourses({ getter, setter, fCurso, setfCurso }) {

    //fecha del curso

    const addFields2 = () => {
        let newfield = ""
        handleInputChange2([...fCurso, newfield])
    }

    const removeFields2 = (index) => {
        let data = [...fCurso];
        data.splice(index, 1)
        handleInputChange2(data);
    }

    const handleInputChange2 = useCallback(event => {
        setfCurso(event)
    }, [setfCurso])

    const handleFormChange2 = (index, event) => {
        let data = [...fCurso];
        data[index] = event.target.value;
        handleInputChange2(data);
    }





    //nombre del curso
    const addFields = () => {
        let newfield = ""
        handleInputChange([...getter, newfield])
        addFields2()
    }

    const removeFields = (index) => {
        let data = [...getter];
        data.splice(index, 1)
        handleInputChange(data);
        removeFields2(index)
    }

    const handleInputChange = useCallback(event => {
        setter(event)
    }, [setter])

    const handleFormChange = (index, event) => {
        let data = [...getter];
        data[index] = event.target.value.slice(0, 20);
        handleInputChange(data);
    }


    return (
        <>
            <div class="form-group">
                <label for="inputsm" className='inputsm'>Agregar Capacitación o cursos <span></span></label>
                {
                    getter.map((frase, index) => (
                        <div className="dy-form-j">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }} >

                                <div
                                    style={{
                                        marginRight: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                    }}
                                >

                                    <label for="inputsm" className='inputsm'><span>Nombre del Curso/Capacitación</span></label>
                                    <input key={index} class="form-control input-sm smal2" type="text" value={getter[index]} onChange={event => handleFormChange(index, event)} />
                                </div>
                                <div
                                    style={{

                                        marginRight: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                    }}

                                >
                                    <label for="inputsm" className='inputsm'><span>Fecha de Finalizacion</span></label>
                                    <input key={index} class="form-control input-sm smal2" type="date" value={fCurso[index]} onChange={event => handleFormChange2(index, event)} />
                                </div>


                            </div>
                            <div className="deleteicon">
                                <FontAwesomeIcon key={index} icon={faTrash} onClick={e => removeFields(index)} />
                            </div>
                        </div>
                    ))
                }
                <div className="addFases">
                    <FontAwesomeIcon icon={faCirclePlus} onClick={addFields} />
                </div>
            </div>
        </>
    )
}

export default DyInputCourses