import React from 'react'
import Users from '../../Components/Users'
import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { useLocation } from "react-router-dom";
//import use state and use effect
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import JobVisualOne from '../../Components/JobVisualOne';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../Pages/assets/css/DashRoot.css'
import logo from '../../Pages/assets/images/lgo1.png'





export default function JobComparator({ isActive, setisActive, editid, seteditid, postulid, setpostulid }) {

    const { setAuth } = useContext(AuthContext);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState(null);
    const [job, setJob] = useState(null);


    const notify2 = () => toast.success("Se modifico la informacion correctamente.");
    const notify = () => toast.error("Error en el Server.");



    useEffect(() => {

        const getpostul = async () => {
            try {
                const response = await axiosPrivate.get(`/jobinf/${editid}`);

                setJob(response.data);

                console.log(response.data);
            } catch (err) {
                //console.error(err)
                if (err.code != "ERR_CANCELED")
                    navigate('/login', { state: { from: location }, replace: true });

            }
        }

        getpostul();

    }, [])


    
    const reload = async () => {
        try {
            const response = await axiosPrivate.get(`/jobinf/${editid}`);

            setJob(response.data);
            notify2();
        } catch (err) {
            //console.error(err)
            if (err.code != "ERR_CANCELED")
                navigate('/login', { state: { from: location }, replace: true });
        }
    }


    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setisActive(current => !current);

    };

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        navigate('/admin');
    }

    const handleAcept = (id) => {

        const PostJob = async () => {
            try {
                const response = await axiosPrivate.put(`/poostulacept/${id}`, {
                    status: "aceptado"
                });
                setTimeout(() => {
                    reload();
                }, 200);


            } catch (err) {

                if (err.code != "ERR_CANCELED")
                    notify();
                //tiempo de espera para que el usuario lea el mensaje
                setTimeout(() => {

                    navigate('/login', { state: { from: location }, replace: true });
                    logout();

                }, 2000);
            }

        }

        PostJob();


    }


    const handleReject = (id) => {

        const PostJob = async () => {
            try {
                const response = await axiosPrivate.put(`/poostulreject/${id}`, {
                    status: "rechazado"
                });
                setTimeout(() => {
                    reload();
                }, 200);


            } catch (err) {

                if (err.code != "ERR_CANCELED")
                    notify();
                //tiempo de espera para que el usuario lea el mensaje
                setTimeout(() => {

                    navigate('/login', { state: { from: location }, replace: true });
                    logout();

                }, 2000);
            }

        }

        PostJob();

    }



    if (job == null) {
        return null
    }

    return (
        <>

            <ToastContainer position="top-center" />

            <nav key={job.idJob} class={isActive ? "sidebar open" : "sidebar close"}>
                <header>
                    <div class="image-text">
                        <span class="image">
                            <img src={logo} alt="" />
                        </span>

                        <div class="text logo-text">
                            <span class="name">Innovatia</span>
                        </div>
                    </div>

                    <i class='bx bx-chevron-right toggle' onClick={handleClick}></i>
                </header>

                <div class="menu-bar">
                    <div class="menu">

                        <ul class="menu-links">
                            <li class="nav-link">
                                <Link to="/admin">
                                    <i class='bx bx-home-alt icon'></i>
                                    <span class="text nav-text  ">Dashboard</span>
                                </Link>
                            </li>

                            <li class="nav-link" style={{
                                backgroundColor: '#695CFE',
                                borderRadius: '6px',
                                color: 'white',
                            }}>
                                <Link to="/registro">
                                    <i style={{
                                        color: 'white',
                                    }} class='bx bx-bell icon  ' ></i>
                                    <span style={
                                        { color: 'white' }
                                    } class="text nav-text">Registro</span>
                                </Link>
                            </li>



                            <li class="nav-link">
                                <Link to="/users">
                                    <i class='bx bx-bar-chart-alt-2 icon' ></i>
                                    <span class="text nav-text">Postulantes </span>
                                </Link>
                            </li>



                            <li class="nav-link">
                                <Link to='/config'>
                                    <i class='bx bx-pie-chart-alt icon' ></i>
                                    <span class="text nav-text">Config</span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div class="bottom-content">
                        <li class="">
                            <a onClick={logout}>
                                <i class='bx bx-log-out icon' ></i>
                                <span class="text nav-text">Logout</span>
                            </a>
                        </li>
                    </div>
                </div>

            </nav>

            <section class="homes" style={
                { height: "60rem" }
            }>
                <div class="text" >
                    <div class="registercontainer2" style={
                        {
                            display: "flex",
                            justifyContent: "space-evenly",
                        }
                    }>

                        {
                            job.postulantes.length == 0
                                ? <p> Por el momento no hay postulantes para este puesto</p>
                                : <>
                                    <JobVisualOne job={job.job} />
                                    <div
                                        style={
                                            {
                                                height: "50rem",
                                                overflowY: "scroll",



                                            }
                                        }>

                                        {
                                            job.postulantes.map((postul) => {
                                                return (
                                                    <div style={{
                                                        marginBottom: "2rem",
                                                    }}>
                                                        {/* aqui va el postulvusual  */}

                                                        <>


                                                            <div className='card32' style={{
                                                                padding: '0px',
                                                                margin: '0px',
                                                            }}>
                                                                <div className='card4' style={
                                                                    {
                                                                        marginBottom: '0px',
                                                                        height: 'auto',
                                                                        overflow: 'visible',
                                                                    }
                                                                }>
                                                                    <div>
                                                                        <div className='Card2Title'> Nombre: {postul.nombre}</div>
                                                                    </div>

                                                                    <div className='.Card2em'>Apellidos: {postul.apellidos}</div>
                                                                    <div className='.Card2em'>Tel: {postul.tel}</div>
                                                                    <div className='.Card2em'>E-mail: {postul.email}</div>
                                                                    <div className='.Card2em'>Linked-In: {postul.linked}</div>
                                                                    <div className='.Card2em'>Status: {postul.status}</div>

                                                                    <div style={
                                                                        { display: 'flex' }
                                                                    }>

                                                                        <div className="col-12 col-md-auto" style={{
                                                                            marginRight: '10px',
                                                                        }}>
                                                                            <button className='addJobs' id={postul.idPostulante} onClick={(e) => { handleAcept(e.currentTarget.id) }} >Aceptar</button>
                                                                        </div>
                                                                        <div className="col-12 col-md-auto">
                                                                            <button className="addJobs" style={{
                                                                                backgroundColor: 'red',
                                                                            }} href="#benefits-section" id={postul.idPostulante} onClick={(e) => { handleReject(e.currentTarget.id) }}>Rechazar</button>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className='scrollable-div'>
                                                                    <div className='cardinsider'>
                                                                        <div>
                                                                            <div className='Card2Title'>Información Personal</div>

                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Curp:</span> {postul.curp}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Domicilio:</span> {postul.domicilio}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Calle y Numero:</span> {postul.calle_Num}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Colonia:</span> {postul.col}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>CP:</span> {postul.cp}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Lugar de Nacimiento:</span> {postul.lugarN}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Fecha de Nacimineto:</span> {postul.fechaN}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Edad:</span> {postul.edad}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Sexo:</span> {postul.sexo}</div>
                
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Nacionalidad:</span> {postul.nacionalidad}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Estado Civil:</span> {postul.Ecivil}</div>
                                                                            <div className='.Card2em'><span style={{ fontWeight: 'bold', display: 'inline-block' }}>Licencia:</span> {postul.licencia}</div>
                                                                        </div>

                                                                    </div>

                                                                    <div className='cardinsider'>
                                                                        <div>
                                                                            <div className='Card2Title'>Formación académica</div>
                                                                            {
                                                                                postul.fA.map((f) =>
                                                                                    <div className='benefits'>{f}</div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className='cardinsider'>
                                                                        <div>
                                                                            <div className='Card2Title'>Habilidades blandas</div>
                                                                            {
                                                                                postul.habilidadesB.map((f) =>
                                                                                    <div className='benefits'>{f}</div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className='cardinsider'>
                                                                        <div>
                                                                            <div className='Card2Title'>Experiencia Laboral</div>
                                                                            {
                                                                                postul.jobE.map((f, index) => (
                                                                                    <div className='benefits'>
                                                                                        <div style={{
                                                                                            justifyContent: 'start'
                                                                                        }}>

                                                                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Nombre Empresa:</span> {f}</div>
                                                                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Nombre Puesto:</span> {postul.JobP[index]}</div>
                                                                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Fecha Inicio:</span> {postul.JobFI[index]}</div>
                                                                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Fecha Fin:</span> {postul.JobFF[index]}</div>


                                                                                        </div>

                                                                                    </div>
                                                                                )
                                                                                )

                                                                            }
                                                                        </div>
                                                                    </div>


                                                                    <div className='cardinsider'>
                                                                        <div>
                                                                            <div className='Card2Title'>Capacitación o cursos</div>
                                                                            {
                                                                                postul.nCurso.map((f, index) => (
                                                                                    <div className='benefits'>
                                                                                        <div style={{
                                                                                            justifyContent: 'start'
                                                                                        }}>

                                                                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Nombre Curso:</span> {f}</div>
                                                                                            <div> <span style={{ fontWeight: 'bold', display: 'inline-block' }}>Fecha:</span> {postul.fCurso[index]}</div>

                                                                                        </div>

                                                                                    </div>
                                                                                )
                                                                                )

                                                                            }
                                                                        </div>
                                                                    </div>


                                                                    <div className='cardinsider'>
                                                                        <div>
                                                                            <div className='Card2Title'>Idiomas y nivel</div>
                                                                            {
                                                                                postul.lang.map((f, index) => (
                                                                                    <div className='benefits'>
                                                                                        <div style={{
                                                                                            justifyContent: 'start'
                                                                                        }}>

                                                                                            <div> {f}</div>


                                                                                        </div>

                                                                                    </div>
                                                                                )
                                                                                )

                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className='cardinsider'>
                                                                        <div>
                                                                            <div className='Card2Title'>Resumen Profecional</div>
                                                                            {postul.resume}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div >

                                                        </>















                                                        {/* aqui termina el postul visual  */}
                                                    </div>

                                                )
                                            }
                                            )
                                        }
                                    </div>
                                </>


                        }





                    </div>
                </div>
                <div style={
                    {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }
                } className="footer-bottom text-center ">
                    <div className="copyright">
                        innovatia.mx
                    </div>
                </div>
            </section>

        </>
    )
}
