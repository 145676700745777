import React from 'react'
//import  useEfect and useState from react
import { useEffect, useState, useContext } from 'react'
import { useNavigate, Link, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import logo from '../Pages/assets/images/lgo1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faGear} from '@fortawesome/free-solid-svg-icons'
import DynamicInputSmall from './DynamicInputSmall';


import useAxiosPrivate from "../hooks/useAxiosPrivate";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { es } from 'faker/lib/locales';


export default function JobAdder({ isActive, setisActive }) {

	//create a state for the jobs 
	const { setAuth } = useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate()
	const controller = new AbortController();

	const [job, setJob] = useState({

		nombreEmpresa: "",
		nombreTrabajo: "",
		tipo: "user",
		horas: "",
		salario: "",
		des: "",
		beneficios: "",
		fullDes: "",
		ub: "",

	})
	const [salari, setsalari] = useState({
		one: "",
		two: "",
		time: "mes",
	})

	const [ben, setben] = useState(["", "", ""])

	const notify = () => toast.error("Todos los campos son obligatorios.");

	const notify4 = () => toast.error("Error en el server");
	const notify2 = () => toast.error("Este usuario ya existe.");
	const notify3 = () => toast.success("Usuario creado con exito.");

	const handleJobPost = (event) => {

		const PostJob = async () => {
			try {
				const response = await axiosPrivate.post('/register', {
					user:job.nombreEmpresa,
					pwd:job.nombreTrabajo,
					roles:[job.tipo]
				});

				if (response.status == 200) {
					notify3();

					setTimeout(() => {
						navigate('/config');
					}, 1000);
				} 

		

			} catch (err) {

				if  (err.response.status == 409){
					notify2();
				}

				if (err.code != "ERR_CANCELED" && err.response.status != 409  )
				{
					notify4();
					setTimeout(() => {

						navigate('/login', { state: { from: location }, replace: true });
						logout();
					}, 2000);
				}

				//tiempo de espera para que el usuario lea el mensaje
	
			}

		}



		if (job.nombreEmpresa == "" || job.nombreTrabajo == "") {
			notify();
			
		} else {
		
			
			PostJob();
		}
	}




	const handleFormInfoChange = (event) => {
		setJob({
			...job,
			[event.target.name]: event.target.value.slice(0, 50)
		})

	}
	const handleFormInfoChange2 = (event) => {
		setsalari({
			...salari,
			[event.target.name]: event.target.value.slice(0, 50)
		})
	}


	const logout = async () => {
		// if used in more components, this should be in context 
		// axios to /logout endpoint 
		setAuth({});
		navigate('/admin');
	}

	const handleClick = event => {
		// 👇️ toggle isActive state on click
		setisActive(current => !current);
	};

	const handleBack = e => {

		navigate('/config');

	}



	return (
		<>
			<ToastContainer

				position="top-center" />
			<nav class={isActive ? "sidebar open" : "sidebar close"}>
				<header>
					<div class="image-text">
						<span class="image">
							<img src={logo} alt="" />
						</span>

						<div class="text logo-text">
							<span class="name">Innovatia</span>
						</div>
					</div>

					<i class='bx bx-chevron-right toggle' onClick={handleClick}></i>
				</header>

				<div class="menu-bar">
					<div class="menu">

						<ul class="menu-links">



							<li class="nav-link">
								<Link to="/admin">
									<i class='bx bx-home-alt icon' ></i>
									<span class="text nav-text">Dashboard </span>
								</Link>
							</li>
							<li class="nav-link">
								<Link to='/registro'>
									<i class='bx bx-bar-chart-alt-2 icon' ></i>
									<span class="text nav-text">Registro</span>
								</Link>
							</li>


							<li class="nav-link">
								<Link to="/users">
									<i class='bx bx-bell icon'></i>
									<span class="text nav-text">Postulantes</span>
								</Link>
							</li>

							<li class="nav-link" style={{
								backgroundColor: '#695CFE',
								borderRadius: '6px',
								color: 'white',
							}}>
								<Link to="/config">
									<i style={{
										color: 'white',
									}} class='bx bx-pie-chart-alt icon' ></i>
									<span style={
										{ color: 'white' }
									} class="text nav-text">Config</span>
								</Link>
							</li>
						</ul>
					</div>

					<div class="bottom-content">
						<li class="">
							<a onClick={logout}>
								<i class='bx bx-log-out icon' ></i>
								<span class="text nav-text">Logout</span>
							</a>
						</li>
					</div>
				</div>

			</nav>


			<section class="homes">
				<div class="text" style={{
					display: "flex",
					felxDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100%"
				}}>
					<div className='registercontainer' style={
						{
				
							overflowX: 'hidden',
						}
					}>



						<div className='formTitle' style={{ width: '100%' }}>

							<div style={{
								display: 'flex',
								alignItems: 'center',
								gap: '20px',
								width: '40rem'
							}}>
								<div className='back' style={{ backgroundColor: '#695CFE', }} >
									<FontAwesomeIcon icon={faArrowLeft} onClick={handleBack} />
								</div>

								<span className="formTitle" style={{ color: '#695CFE', }}>Registrar Usuario</span>
							</div>

							<div style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'flex-end',
							}}>


								<FontAwesomeIcon icon={faFloppyDisk} className="saveJob" style={{
									padding: '0px',
									margin: '0px',
									color: '#695CFE',
									fontSize: '1.4rem',
								}} onClick={handleJobPost} />


							</div>

						</div>
						<div style={{
					
						}}>

							<div>
								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Nombre de Usuario<span>(obligatorio)</span></label>
									<input class="form-control input-sm smal" name="nombreEmpresa" type="text" value={job.nombreEmpresa} onChange={handleFormInfoChange} />
								</div>


								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Contraseña<span>(obligatorio)</span></label>
									<input class="form-control input-sm smal" name="nombreTrabajo" type="text" value={job.nombreTrabajo} onChange={handleFormInfoChange} />
								</div>

								<div class="form-group">
									<label style={{
										fontSize: '13px',
									}} for="inputsm" className='inputsm'>Tipo<span>(obligatorio)</span></label>
									<select className='input-sm smal2 form-select' name="tipo" type="text" value={job.tipo} onChange={handleFormInfoChange}>
										<option value="">user</option>
										<option value="admin">admin</option>
										
									</select>
								</div>

							</div>

				



						</div>








					</div>
				</div>
				<div style={
					{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}
				} className="footer-bottom text-center ">
					<div className="copyright">
						innovatia.mx
					</div>
				</div>
			</section>



		</>
	)
}
