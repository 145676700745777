
import React from 'react'
import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home'
import Jobs from './Pages/Jobs'
import Form from './Pages/Form'
import Login from './Pages/Login'
import Unauth from './Pages/Unauth';
import DashRoot from './Pages/DashRoot';
import DashUser from './Pages/DashUser';
import Mising from './Pages/Mising';
import Registro from './Pages/DashRoot/Registro';
import JobAdder from './Components/JobAdder';
import EditJobs from './Components/EditJobs';
import Postulantes from './Pages/DashRoot/Postulantes'
import Comparator from './Pages/DashRoot/Comparator';
import JobComparator from './Pages/DashRoot/JobComparator';
import Config from './Pages/DashRoot/Config';
import UserAdder from './Components/UserAdder';
import ConfigureUser from './Components/ConfigureUser';

import { useEffect, useState } from 'react';
import { AuthProvider } from './context/AuthProvider'

import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

import RequireAuth from './Components/RequireAuth';


import Layout from './Components/Layout'




function App() {

  const [masterid, setmasterid] = useState(null)
  const [isActive, setisActive] = useState(false);

  const [editid, seteditid] = useState(null);
  const [postulid, setpostulid] = useState(null);



  return (

    <React.StrictMode>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>

              {/* Public Routes */}

              <Route path="/" element={<Home />} />
              <Route path="/bolsadetrabajo" element={<Jobs masterid={masterid} setmasterid={setmasterid} />} />
              <Route path="form" element={<Form masterid={masterid} setmasterid={setmasterid} />} />
              <Route path="login" element={<Login />} />
              <Route path="unauthorized" element={<Unauth />} />

              {/* Protected Routes */}

              <Route element={<RequireAuth allowedRoles={[2001, 5150]} />}>


                <Route path="admin" element={<DashRoot isActive={isActive} setisActive={setisActive} />} />
                <Route path="registro" element={<Registro isActive={isActive} setisActive={setisActive} editid={editid} seteditid={seteditid} />} />
                <Route path="addJob" element={<JobAdder isActive={isActive} setisActive={setisActive} />} />
                <Route path="editJob" element={<EditJobs isActive={isActive} setisActive={setisActive} editid={editid} seteditid={seteditid} />} />
                <Route path="Jcomp" element={<JobComparator isActive={isActive} setisActive={setisActive} editid={editid} seteditid={seteditid} postulid={postulid} setpostulid={setpostulid} />} />


                <Route path="users" element={<Postulantes isActive={isActive} setisActive={setisActive} editid={editid} seteditid={seteditid} />} />
                <Route path="comp" element={<Comparator isActive={isActive} setisActive={setisActive} editid={editid} seteditid={seteditid} postulid={postulid} setpostulid={setpostulid} />} />


              </Route>

              <Route element={<RequireAuth allowedRoles={[5150]} />}>


                <Route path="config" element={<Config isActive={isActive} setisActive={setisActive} editid={editid} seteditid={seteditid} postulid={postulid} setpostulid={setpostulid} />} />
                <Route path="useradder" element={<UserAdder isActive={isActive} setisActive={setisActive} editid={editid} seteditid={seteditid} postulid={postulid} setpostulid={setpostulid} />} />
                <Route path="userconf" element={<ConfigureUser isActive={isActive} setisActive={setisActive} editid={editid} seteditid={seteditid} postulid={postulid} setpostulid={setpostulid} />} />


              </Route>










              <Route path="*" element={<Mising />} />
            </Route>
          </Routes>

        </BrowserRouter>

      </AuthProvider>

    </React.StrictMode>

  );

}

export default App;
