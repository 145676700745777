import React, { Component } from 'react'
import { useEffect, useState } from 'react';
import './assets/css/theme.css'
import Cards from '../Components/Card'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'

import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link, useLocation } from "react-router-dom";

import logo from './assets/images/site-logo.jpg'

import Spiner from '../Components/Spiner'
import axios from 'axios';

import JobVisual from '../Components/JobVisual'
import notdounf from '../Pages/assets/images/404.svg'

import Aos from 'aos'
import 'aos/dist/aos.css'
import { textAlign } from '@mui/system';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import MasterURL from '../api/api';

import { useMediaQuery } from 'react-responsive'



const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 991 })
    return isMobile ? children : null
}


function Jobs({ masterid, setmasterid }) {

    let counter = 0

    const navigate = useNavigate();
    let URL = MasterURL + "jobs";

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])






    const [data, setdata] = useState(null)
    const [loading, setLoading] = useState(false)
    const [filterdata, setfilterdata] = useState(null)



    const step = 10
    const [leftptr, setleftptr] = useState(0);
    const [rtptr, setrtptr] = useState(step);

    const notify = () => toast.error("¡Sin caracteres especiales ni números, por favor!");


    useEffect(() => {

        setLoading(true)
        axios.get(URL).then((res) => {
            setdata(res.data)
            //console.log(data)
        });

    }, [])




    useEffect(() => {
        //console.log(filterdata)
        try {
            if (data != null && filterdata != null) {
                setmasterid(filterdata[0].idJob)
            }
        } catch (error) {
            console.log(error)
        }

    }, [filterdata])

    useEffect(() => {
        if (data != null) {

            setfilterdata(data)
            setTimeout(function () {
                setLoading(false)
            }, 1500);
        }
    }
        , [data])



    const [query, setQuery] = useState("")
    const [query1, setQuery1] = useState("")
    const [queryid, setQueryid] = useState(0)

    const handlesearch = (e) => {

        setQuery1(e)
        setleftptr(0)
        setrtptr(step)


    }
    useEffect(() => {
        if (query === "") {
            setfilterdata(data)
        }
        if (data) {
            if (data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).length === 0) {
                setfilterdata([
                    {
                        "nombreEmpresa": "",
                        "nombreTrabajo": "",
                        "tipo": "",
                        "horas": "",
                        "salario": [],
                        "des": "",
                        "beneficios": [],
                        "ub": "",
                        "fullDes": ""
                    }
                ])
            } else {
                setfilterdata(data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())))
            }
        }

    }, [query])


    const handlesearchfull = (e) => {
        const onlyLettersPattern = /^[A-Za-z\u00C0-\u017F ]+$/;


        if (!query1.match(onlyLettersPattern)) {

            if (query1.length == 0) {
                setQuery(query1)
                setQueryid(0)
                setLoading(true)
                setTimeout(function () {
                    setLoading(false)
                }, 700);
            } else {
                notify()
            }
        } else {

            setQuery(query1)
            setQueryid(0)
            setLoading(true)
            setTimeout(function () {
                setLoading(false)
            }, 700);
        }
    }

    const handleClik = (e) => {
        setQueryid(e.currentTarget.id)
        setmasterid(filterdata[e.currentTarget.id].idJob)
    }


    const handleClikm = (e) => {
        setQueryid(e.currentTarget.id)
        setmasterid(filterdata[e.currentTarget.id].idJob)

        navigate('/form')
    }

    const onfromsub = e => {
        e.preventDefault()
    }


    function filtered() {
        if (data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).length === 0) {
            return false
        } else {
            return true
        }
    }

    const handlerpointer = (e) => {

        if (data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).length + step < rtptr) {

        } else {
            window.scrollTo(0, 0)
            setrtptr(rtptr + step)
            setleftptr(leftptr + step)
        }

    }
    const handlerleftptr = (e) => {

        if (leftptr == 0) {
            setrtptr(step)
            setleftptr(0)
        } else {
            setrtptr(rtptr - step)
            setleftptr(leftptr - step)
        }

    }

    const resetPointers = () => {
        setrtptr(step)
        setleftptr(0)
    }

    const resetQuery = () => {
        setQuery("")
    }



    if (!data || !filterdata || data.length == 0) {
        return (
            <div className='notFound' style={
                {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                }
            }>
                <Spiner loading={true} />
            </div>
        )
    } else {

        return (

            <>
                <Desktop>
                    <div className="jobs">

                        <ToastContainer />
                        <div className="header">
                            <div className="branding">
                                <div className="container-fluid position-relative py-3">
                                    <Link to="/"className="logo-wrapper">
                                        <div className="site-logo"><a className="navbar-brand" ><img className="logoIno" src={logo} alt="logo" />
                                        </a></div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                        <section id="form-section" className="form-section">
                            <div className="container">
                                <h3 data-aos="fade-left" className="text-center">Buscar Trabajo </h3>
                                <div className="form-wrapper mx-auto">
                                    <form onSubmit={onfromsub} className="signup-form row g-2 align-items-center">
                                        <div className="col-12 col-lg-9">
                                            <input id="semail" name="semail1" className="form-control me-md-1 semail" placeholder="Buscar por Nombre del empleo Ejemplo : Mecatronico ..."
                                                onChange={e => handlesearch(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-3">
                                            <button className="btn btn-primary btn-submit w-100" onClick={handlesearchfull}>Buscar</button>
                                        </div>
                                    </form>

                                    <div className='jobesfinder jobsinfo'>
                                        <ul className='list2 '  >
                                            {data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).slice(leftptr, rtptr).map((job, key) => (
                                                <li className='card2' id={key} onClick={e => handleClik(e)} >
                                                    <div className=''>
                                                        <div className='Card2Title'>{job.nombreTrabajo}</div>

                                                        <div className='Card2em'>{job.nombreEmpresa}</div>

                                                        <div className='Card2Ub'>

                                                            <div className='iconm'>
                                                                <FontAwesomeIcon icon={faLocationPin} />
                                                            </div> {job.ub}

                                                        </div>

                                                        <div>
                                                            Modalidad: {job.modalidad}
                                                        </div>
                                                        <div className='padintopbot'>


                                                            <div className=' Card2Ujtype theme-bg-light rounded'>

                                                                <div className='iconm'>
                                                                    <FontAwesomeIcon icon={faMoneyBill} />
                                                                </div>

                                                                {job.salario[0]}.00-{job.salario[1]}.00/{job.salario[2]}
                                                            </div>

                                                            <div className='Card2Salari theme-bg-light rounded'>
                                                                <div className='iconm'>
                                                                    <FontAwesomeIcon icon={faBriefcase} />
                                                                </div>
                                                                {job.tipo}
                                                            </div>

                                                            <div className=' Card2Ujtype2 theme-bg-light rounded'>

                                                                <div className='iconm'>
                                                                    <FontAwesomeIcon icon={faClock} />
                                                                </div>
                                                                Turno de {job.horas} horas
                                                            </div>

                                                        </div>

                                                        <div>
                                                            {job.des}

                                                        </div>

                                                        <div className='dateinfo'> Publicado el : {job.date.split("T")[0]}</div>

                                                    </div>
                                                </li>


                                            ))
                                            }



                                            {
                                                data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).slice(leftptr, rtptr).length == 0 ?
                                                    <>
                                                        <div className='notFound'>
                                                            <img src={notdounf} alt="" />

                                                            <h6>
                                                                No hay trabajos que coincidan con tu búsqueda.


                                                                {data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).length == 0 ?
                                                                    <>
                                                                        <div style={{
                                                                            cursor: "pointer",
                                                                            fontSize: "1.2rem",
                                                                            textAlign: "center",
                                                                            marginTop: "1rem",
                                                                        }} onClick={resetQuery}>
                                                                            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Regresar
                                                                        </div>


                                                                    </>
                                                                    : <>

                                                                        <div style={{
                                                                            cursor: "pointer",
                                                                            fontSize: "1.2rem",
                                                                            textAlign: "center",
                                                                            marginTop: "1rem",
                                                                        }} onClick={resetPointers}>
                                                                            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Regresar
                                                                        </div>

                                                                    </>


                                                                }


                                                            </h6>
                                                        </div>
                                                    </>

                                                    : <>

                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "space-evenly",

                                                        }}>
                                                            <div style={{
                                                                fontSize: "1.5rem",
                                                                cursor: "pointer"


                                                            }} onClick={handlerleftptr} >

                                                                <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Anterior
                                                            </div>
                                                            <div
                                                                style={{
                                                                    fontSize: "1.5rem",
                                                                    cursor: "pointer"

                                                                }} onClick={handlerpointer} >
                                                                <FontAwesomeIcon icon={faArrowAltCircleRight} /> Ver más ..
                                                            </div>
                                                        </div>


                                                    </>


                                            }




                                        </ul>

                                        <JobVisual data={data} filterdata={filterdata} query={query} queryid={queryid} />
                                    </div>
                                </div>

                            </div>
                        </section>

                        <Spiner loading={loading} />
                        <div class="footer">

                            <div class="footer-bottom text-center py-5">
                                <div class="copyright">
                                    innovatia.mx
                                </div>
                            </div>


                        </div>
                    </div>


                </Desktop>

                <Mobile>

                    <div className="jobs" style={
                        {
                            overflowX: "hidden",
                        }
                    }>
                        <ToastContainer />

                        <div className="header">
                            <div className="branding">
                                <div className="container-fluid position-relative py-3">
                                    <Link to="/"className="logo-wrapper">
                                        <div className="site-logo"><a className="navbar-brand" ><img className="logoIno" src={logo} alt="logo" />
                                        </a></div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                        <h3 data-aos="fade-left" className="text-center">Buscar Trabajo </h3>

                        <div style={
                            {
                                width: "100%",
                                padding: "1rem",
                                justifyContent: "center",
                            }
                        }   >
                            <form onSubmit={onfromsub} className="signup-form row g-2 align-items-center">
                                <div className="col-12 col-lg-9">
                                    <input id="semail" name="semail1" className="form-control me-md-1 semail" placeholder="Busca por nombre Ejemplo : Mecatronico ..."
                                        onChange={e => handlesearch(e.target.value)}
                                    />
                                </div>
                                <div className="col-12 col-lg-3">
                                    <button className="btn btn-primary btn-submit w-100" onClick={handlesearchfull}>Buscar</button>
                                </div>
                            </form>
                        </div>


                        <ul style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0",
                            padding: "0",

                        }}  >
                            {data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).slice(leftptr, rtptr).map((job, key) => (
                                <li className='card2m'  >
                                    <div className=''>
                                        <div className='Card2Title'>{job.nombreTrabajo}</div>


                                        <div className='.Card2em'>{job.nombreEmpresa}</div>

                                        <div className='Card2Ub'>

                                            <div className='iconm'>
                                                <FontAwesomeIcon icon={faLocationPin} />
                                            </div> {job.ub}

                                        </div>

                                        <div>
                                            Modalidad: {job.modalidad}
                                        </div>
                                        <div className='padintopbot'>


                                            <div className=' Card2Ujtype theme-bg-light rounded'>

                                                <div className='iconm'>
                                                    <FontAwesomeIcon icon={faMoneyBill} />
                                                </div>

                                                {job.salario[0]}.00-{job.salario[1]}.00/{job.salario[2]}
                                            </div>

                                            <div className='Card2Salari theme-bg-light rounded'>
                                                <div className='iconm'>
                                                    <FontAwesomeIcon icon={faBriefcase} />
                                                </div>
                                                {job.tipo}
                                            </div>

                                            <div className=' Card2Ujtype2 theme-bg-light rounded'>

                                                <div className='iconm'>
                                                    <FontAwesomeIcon icon={faClock} />
                                                </div>
                                                Turno de {job.horas} horas
                                            </div>

                                        </div>

                                        <div>
                                            {job.des}
                                        </div>

                                        <div className='postulbot'>
                                            <div className="btn btn-primary btn-submit" id={key} onClick={e => handleClikm(e)} >Postularse ahora</div>
                                        </div>

                                        <div className='dateinfo'> Publicado el : {job.date.split("T")[0]}</div>

                                    </div>
                                </li>


                            ))
                            }



                            {
                                data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).slice(leftptr, rtptr).length == 0 ?
                                    <>
                                        <div className='notFound' style={{
                                            width: "100%",
                                            padding: "1.5rem",
                                            textAlign: "center",
                                        }}>
                                            <img src={notdounf} style={{
                                                width: "95%",
                                            }} alt="" />

                                            <h6>
                                                No hay trabajos que coincidan con tu búsqueda.

                                                {data.filter((j) => j.nombreTrabajo.toLowerCase().includes(query.toLowerCase())).length == 0 ?
                                                    <>
                                                        <div style={{
                                                            cursor: "pointer",
                                                            fontSize: "1.2rem",
                                                            textAlign: "center",
                                                            marginTop: "1rem",
                                                        }} onClick={resetQuery}>
                                                            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Regresar
                                                        </div>


                                                    </>
                                                    : <>

                                                        <div style={{
                                                            cursor: "pointer",
                                                            fontSize: "1.2rem",
                                                            textAlign: "center",
                                                            marginTop: "1rem",
                                                        }} onClick={resetPointers}>
                                                            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Regresar
                                                        </div>

                                                    </>


                                                }



                                            </h6>
                                        </div>
                                    </>

                                    : <>

                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "1.5rem",
                                        }}>
                                            <div style={{
                                                fontSize: "1rem",
                                                cursor: "pointer"


                                            }} onClick={handlerleftptr} >

                                                <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Anterior
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "1rem",
                                                    cursor: "pointer"

                                                }} onClick={handlerpointer} >
                                                <FontAwesomeIcon icon={faArrowAltCircleRight} /> Ver más ..
                                            </div>
                                        </div>


                                    </>


                            }




                        </ul>








                    </div>






                </Mobile>
            </>
        )
    }
}

export default Jobs